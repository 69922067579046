<template>
  <div class="animated fadeIn" :class="{ loadingclass: loading }">
    <div>
      <rotate-square2 v-if="loading" id="loading-gif"></rotate-square2>
    </div>
    <!-- THIS MODAL IS TO SEE ALL THE INVOICES -->
    <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
      <b-card>
        <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
        <div class="row" v-if="!loading">
          <h4><i class="fal fa-file-chart-line"></i> All Invoice</h4>
          <hr />
          <div class="col-md-12">
            <v-client-table :data="listOfInvoices" id="rt-master-invoice-table-2" :columns="masterInvoiceModalColumns"
              :options="masterInvoiceModalOptions" :theme="theme">
              <div slot="invoiceNumber" slot-scope="props">
                {{ props.row.invnum }}
              </div>
              <div slot="submissionDate" slot-scope="props">
                {{ props.row.creation_date | moment('M/D/YYYY') }}
              </div>
              <div slot="subtotalWithoutDiscountAndGst" slot-scope="props">
                ${{ formatPrice(props.row.subtotalWithoutDiscountAndGst) }}
              </div>
              <div slot="subtotalWithDiscount" slot-scope="props">
                ${{ formatPrice(props.row.subtotalWithDiscount) }}
              </div>
              <div slot="totalWithDiscount" slot-scope="props">
                <!--  ${{formatPrice(  (props.row.total + (props.row.total  * (props.row.discount / 100))) )  }} -->
                ${{
                    formatPrice(
                      props.row.totalWithDiscount +
                      (props.row.totalWithDiscount * props.row.tax_percentage) /
                      100
                    )
                }}
              </div>
              <div slot="status" slot-scope="props">
                <InvoiceStatus :invoice="props.row" :toggle="true"></InvoiceStatus>
              </div>
              <div slot="view" slot-scope="props">
                <!--  <div v-if="!propinvoice"> -->
                <button class="btn-sm btn-blue" v-if="props.row.inv_passed == 0 || props.row.inv_passed == 8"
                  @click="setInvoice(props.row, 1)" v-b-modal.invoiceModal>
                  Open Invoice
                </button>
                <button class="btn-sm btn-blue" v-else @click="setInvoice(props.row, 0)" v-b-modal.invoiceModal>
                  Open Invoice
                </button>
                <!-- </div>
                  <div v-if="propinvoice">
                     <button class="btn-sm btn-disabled disabled" disabled="true">Open Invoice</button>
                  </div> -->
              </div>
            </v-client-table>
          </div>
        </div>
      </b-card>
    </b-modal>
    <!-- THIS MODAL IS FOR THE SINGLE INVOICE  -->
    <b-modal id="invoiceModal" ref="invoiceModal" class="invoice-modal" hide-header ok-only>
      <WTS-Invoice v-if="$session.get('user') == 'pm'" :fromexternal="true" :dontRedirect="true"
        @onStatusUpdate="updateInvoiceStatus"></WTS-Invoice>
      <WSS-Invoice v-if="$session.get('user') == 'wss'" :fromexternal="true" :dontRedirect="true"
        @onStatusUpdate="updateInvoiceStatus"></WSS-Invoice>
      <!-- todo update fromexternal  -->
      <MNG-Invoice v-if="$session.get('user') == 'manager'" :fromexternal="true" :dontRedirect="true"
        @onStatusUpdate="updateInvoiceStatus"></MNG-Invoice>
      <!-- todo update fromexternal  -->
      <MNG-Invoice v-if="$session.get('user') == 'accountant'" :fromexternal="true" :dontRedirect="true"
        @onStatusUpdate="updateInvoiceStatus"></MNG-Invoice>
      <!-- todo update fromexternal  -->
    </b-modal>

    <!-- THIS MODAL IS FOR INVESTIGATION TABLE -->
    <b-modal id="investigate-modal" ref="investigate-modal" class="modal-dialog" hide-header ok-only>
      <div class="col-md-12">
        <WtsInvestigationTable v-if="$session.get('user') == 'pm'" v-bind:filterSelection="filterSelection"
          v-bind:masterInvoiceProjectSelection="projectSelection" v-bind:masterInvoiceAfeSelection="afeSelection"
          v-bind:masterInvoiceVendorSelection="vendorSelection">
        </WtsInvestigationTable>
        <WssInvestigationTable v-if="$session.get('user') == 'wss'" v-bind:filterSelection="filterSelection"
          v-bind:masterInvoiceProjectSelection="projectSelection" v-bind:masterInvoiceAfeSelection="afeSelection"
          v-bind:masterInvoiceVendorSelection="vendorSelection">
        </WssInvestigationTable>
        <ManagerInvestigationTable v-if="$session.get('user') == 'manager'" v-bind:filterSelection="filterSelection"
          v-bind:masterInvoiceProjectSelection="projectSelection" v-bind:masterInvoiceAfeSelection="afeSelection"
          v-bind:masterInvoiceVendorSelection="vendorSelection">
        </ManagerInvestigationTable>
        <AccountantInvestigationTable v-if="$session.get('user') == 'accountant'"
          v-bind:filterSelection="filterSelection" v-bind:masterInvoiceProjectSelection="projectSelection"
          v-bind:masterInvoiceAfeSelection="afeSelection" v-bind:masterInvoiceVendorSelection="vendorSelection">
        </AccountantInvestigationTable>
      </div>
    </b-modal>

    <b-card>
      <h4><i class="fal fa-file-chart-line"></i> Master Invoice</h4>
      <hr />
      <div class="row my-3">
        <div class="col-md-4">
          <h5>Select Project(s)</h5>
          <multiselect @input="onSelectProject()" v-model="projectSelection" track-by="projectid"
            :options="listOfProjects" :multiple="true" :close-on-select="true" label="projectname"></multiselect>
        </div>
        <div class="col-md-4" v-if="listOfAfes.length > 0">
          <h5>
            Select AFE / PO
            <!--  <button @click="displayAfeFilters = !displayAfeFilters" class="btn btn-info btn-sm mx-2"><i class="fas fa-filter"></i> Filter AFEs</button>  <button @click="onFilterChange(true)" class="btn btn-danger btn-sm float"><i class="fas fa-eraser"></i> Clear Filters</button> -->
          </h5>
          <multiselect @input="onSelectAfe()" v-model="afeSelection" :options="listOfAfes" track-by="afenum"
            :multiple="true" :close-on-select="false" label="afenum"></multiselect>
        </div>
        <div class="col-md-4" v-if="afeSelection.length > 0 && listOfAfes.length > 0">
          <h5>Select Vendor</h5>
          <multiselect @input="onSelectVendor()" :multiple="false" v-model="vendorSelection" track-by="vid"
            :options="listOfVendors" label="vendor_company_name">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.vendor_company_name }} ({{
                    props.option.lname
                }})</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-4" v-show="displayNotAfeFoundNotification">
          <h5 style="color: red">Warning</h5>
          No Afe's found for the selected filtering criteria. <br />
          <button @click="onFilterChange(true)" class="btn btn-danger btn-sm float mt-3">
            <i class="fas fa-eraser"></i> Clear Filters
          </button>
        </div>
      </div>
      <div class="row my-1" v-if="displayAfeFilters">
        <div class="col-md-12">
          <h5>Filter AFE(s) / PO(s)</h5>
        </div>
        <div class="col-md-4">
          <h6>By Category</h6>
          <multiselect @input="onFilterChange(false)" :multiple="true" v-model="categoryFilterSelection"
            placeholder="Category" :close-on-select="true" :options="listOfCategories"></multiselect>
        </div>
        <div class="col-md-4">
          <h6>By Well</h6>
          <multiselect @input="onFilterChange(false)" :multiple="true" track-by="uwi" label="label"
            v-model="wellFilterSelection" :options="listOfUwis"></multiselect>
        </div>
      </div>

      <div class="row" v-show="displayBuildInvoiceButton && listOfAfes.length > 0">
        <div class="col-md-12">
          <hr />
        </div>
      </div>

      <div class="row mt-3" v-show="displayBuildInvoiceButton && listOfAfes.length > 0" style="margin-bottom: 3rem">
        <div class="col-md-4">
          <h5>
            Filter By Date (optional)
            <button @click="clearDates()" class="btn btn-info btn-sm float float-right clear-dates">
              <i class="fas fa-eraser"></i> Clear Dates
            </button>
          </h5>
          <v-date-picker class="vc-calendar date-padding" :formats="formats" mode="range" v-model="dateFilterRange"
            show-caps>
          </v-date-picker>
        </div>
        <div class="col-md-8 text-center">
          <button @click="loadInvoices(false)" class="btn btn-primary animated rubberBand mt-3">
            <i class="fas fa-file-invoice-dollar"></i> Generate Master Invoice
          </button>
        </div>
      </div>

      <!-- </b-card> -->

      <div v-if="listOfInvoiceServices.length > 0 && !loading" class="mt-3 clearfix mb-4">
        <b-row>
          <b-col md="4">
            <VendorDetails v-if="invoice" v-bind:invoice="invoice"></VendorDetails>
          </b-col>
          <b-col md="4">
            <CompanyDetails v-if="invoice && mother_company_name" v-bind:invoice="invoice"
              v-bind:company_name="mother_company_name"></CompanyDetails>
          </b-col>
          <b-col md="4">
            <div>
              <h4 class="mb-3">Details</h4>
              <hr />
              <ul style="list-style: none" class="fa-ul">
                <li v-for="afe in groupProjects" style="margin-bottom: 1em">
                  <span class="fa-li"><i class="fa fa-project-diagram"></i></span>{{ afe.name }}

                  <ul v-for="item in afe.info" class="fa-ul" style="margin-left: 1.5em; margin-bottom: 0.25em">
                    <li>
                      <span class="fa-li"><i class="fa fa-tint"></i></span>UWI:
                      {{ item.uwi }}
                    </li>
                    <li>
                      <span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{ item.ln }}
                    </li>
                    <li>
                      <span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{ item.lsdsurface }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="mother_company_name"></CompanyDetails> -->
          </b-col>
        </b-row>
      </div>

      <div v-if="listOfInvoiceServices.length > 0 && !loading">
        <h4><i class="fas fa-sticky-note"></i> Sub Invoice Details</h4>
        <hr />
        <div>
          <div>
            <span style="margin-right: 25px; margin-left: 20px"><strong>Subtotal:</strong> ${{ formatPrice(subtotal)
            }}</span>
            <span style="margin-right: 25px"><strong>GST ({{ invoice.tax_percentage }}%):</strong> ${{
                tax
            }}</span>
            <span style="margin-right: 25px"><strong>Total:</strong> ${{ formatPrice(total) }}</span>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <button class="btn-primary" style="
                padding: 5px;
                border-radius: 0.25rem;
                margin: 15px 21px 15px;
              " @click="viewInvestigationTable()" v-b-modal.investigate-modal>
              <i class="fas fa-eye"></i> Investigate More
            </button>
            <button style="
                padding: 5px;
                border-radius: 0.25rem;
                margin: 15px 21px 15px;
              " @click="viewAllInvoices()" v-b-modal.master-invoice-modal class="btn-info">
              <i class="fas fa-analytics"></i> View all invoices
            </button>
          </div>
        </div>
        <hr />
        <v-client-table :data="tableDataInvoices" id="rt-master-invoice-table" :columns="columnsInvoices"
          :options="options" :theme="theme">
          <template slot="child_row" slot-scope="props">
            <v-client-table class="rt-table-striped rt-master-child-table" :data="props.row.services"
              :columns="childColumnsInvoices" :options="childOptions" :theme="theme" id="master-invoice">
              <div slot="pu" slot-scope="props">
                ${{ props.row.pu }}/{{ props.row.uom1 }}
              </div>
              <div slot="discountedPriceUnit" slot-scope="props">
                {{ formatPrice(props.row.discountedPriceUnit) }}
              </div>

              <div slot="quantity" slot-scope="props">
                {{ props.row.quantity }}
              </div>
              <div slot="service_discount" slot-scope="props">
                {{ formatPrice(props.row.discount) }}%
              </div>

              <div slot="total" slot-scope="props">
                <!-- ${{formatPrice((props.row.quantity * props.row.pu) - ((props.row.discount/100) * props.row.quantity * props.row.pu))}} -->
                ${{ formatPrice(calcLineTotal(props.row)) }}
              </div>
              <div slot="afe_percentage" slot-scope="props">
                {{ formatPrice(props.row.percentage) }}%
              </div>
              <div slot="inv_passed" slot-scope="props" style="padding: 4px">
                <InvoiceStatus :invoice="props.row"></InvoiceStatus>
                <button v-if="props.row.inv_passed == 0 || props.row.inv_passed == 8" class="btn-sm btn-blue"
                  @click="getAndSetInvoice(props.row, 1)">
                  {{ props.row.invnum }}
                </button>
                <button v-else class="btn-sm btn-blue" @click="getAndSetInvoice(props.row, 0)">
                  {{ props.row.invnum }}
                </button>
              </div>
            </v-client-table>
          </template>
        </v-client-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClientTable, Event } from 'vue-tables-2';
import Multiselect from 'vue-multiselect';
import { RotateSquare2 } from 'vue-loading-spinner';
import Datepicker from 'vuejs-datepicker';

import WtsInvestigationTable from './../views/wts/WtsInvestigationTable';
import WssInvestigationTable from './../views/wss/WssInvestigationTable';
import ManagerInvestigationTable from './../views/manager/ManagerInvestigationTable';
import AccountantInvestigationTable from './../views/accountant/AccountantInvestigationTable';

import VendorDetails from './../views/invoice/InvoiceVendorDetails';
import InvoiceHeader from './../views/invoice/InvoiceHeader';
import CompanyDetails from './../views/invoice/InvoiceCompanyDetails';
import InvoiceDetails from './../views/invoice/InvoiceDetails';
import InvoiceTotals from './../views/invoice/InvoiceTotals';
import InvoiceStatus from './../components/InvoiceStatus';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moments = extendMoment(Moment);

Vue.use(ClientTable);

export default {
  name: 'MasterInvoice',
  components: {
    'WTS-Invoice': () => import('../views/wts/WtsInvoice'),
    'MNG-Invoice': () => import('../views/manager/ManagerInvoice.vue'),
    'WSS-Invoice': () => import('./../views/wss/WssInvoice'),
    ClientTable,
    Event,
    Multiselect,
    RotateSquare2,
    Datepicker,
    VendorDetails,
    CompanyDetails,
    InvoiceHeader,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceStatus,

    WtsInvestigationTable,
    WssInvestigationTable,
    ManagerInvestigationTable,
    AccountantInvestigationTable,
  },
  props: ['propinvoice', 'propafes'],
  data() {
    return {
      pus: 0,
      loading: false,
      displayAfeFilters: false,
      displayNotAfeFoundNotification: false,
      displayBuildInvoiceButton: false,
      dateFilterRange: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        dayPopover: 'L',
      },
      listOfProjects: [],
      listOfAfes: [],
      listOfInitalAfes: [],
      listOfVendors: [],
      listOfCategories: [],
      listOfUwis: [],
      listOfInvoiceServices: [],
      listOfInvoices: [],
      tableDataInvoices: [],
      projectSelection: [],
      afeSelection: [],
      vendorSelection: [],
      categoryFilterSelection: null,
      wellFilterSelection: null,
      filterSelection: null,
      subtotal: 0,
      tax: 0,
      total: 0,

      columnsInvoices: [
        'name',
        'type',
        'uom',
        'pu',
        'discountedPriceUnit',
        'quantity',
        'calculatedDiscount',
        'totalwithdiscount',
        'totalwithoutdiscount',
      ],
      options: {
        headings: {
          service_name: 'Service Name',
          type: 'Service Code',
          uom: 'UOM',
          pu: 'Price/Unit',
          quantity: 'Quantity',
          calculatedDiscount: 'Calculated Discount %',
          totalwithdiscount: 'Total With Discount',
          totalwithoutdiscount: 'Total Without Discount',
          discountedPriceUnit: 'Discounted Price/Unit',
        },
        filterable: [
          'name',
          'type',
          'uom',
          'pu',
          'quantity',
          'service_discount',
          'totalwithdiscount',
          'totalwithoutdiscount',
        ],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
        perPage: 40,
      },
      childColumnsInvoices: [
        'pu',
        'discountedPriceUnit',
        'quantity',
        'service_discount',
        'total',
        'afenum',
        'afe_percentage',
        'start_date',
        'end_date',
        'inv_passed',
      ],
      childOptions: {
        headings: {
          pu: 'Price/Unit',
          quantity: 'Quantity',
          service_discount: 'Discount %',
          total: 'Total',
          afenum: 'AFE # / PO',
          afe_percentage: 'AFE / PO Split %',
          start_date: 'Start Date',
          end_date: 'End Date',
          inv_passed: 'Status/View Invoice',
          discountedPriceUnit: 'Discounted Price/Unit',
        },

        filterable: false,
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
      },
      theme: 'bootstrap4',
      template: 'default',
      masterInvoiceModalData: [],
      masterInvoiceModalColumns: [
        'invoiceNumber',
        'submissionDate',
        'subtotalWithoutDiscountAndGst',
        'subtotalWithDiscount',
        'totalWithDiscount',
        'status',
        'view',
      ],
      masterInvoiceModalOptions: {
        headings: {
          invoiceNumber: '#',
          submissionDate: 'Submission Date',
          subtotalWithoutDiscountAndGst:
            'Subtotal Without Discount and Without GST',
          subtotalWithDiscount: 'Subtotal With Discount and Without GST ',
          totalWithDiscount: 'Total With Discount and With GST',
          status: 'Status',
          view: 'View',
        },
        filterable: [
          'invoiceNumber',
          'submissionDate',
          'totalWithoutGst',
          'totalWithGst',
          'totalWithDiscount',
          'status',
        ],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
        sortable: [],
      },
      invoice: null,
      mother_company_name: null,
    };
  },
  methods: {
    async onSelectProject() {
      this.listOfAfes = [];
      this.listOfVendors = [];
      this.vendorSelection = [];
      this.afeSelection = [];

      for (let project of this.projectSelection) {
        await this.$axios
          .get(
            'master_invoice/afes/' + project.projectid + '/' + project.companyid
          )
          .then((response) => {
            this.listOfAfes = this.listOfAfes.concat(response.data.result);
          })
          .catch((error) => {
            this.$toasted.show('Error. ' + error.message, {
              type: 'error',
              duration: '3000',
            });
          });
      }

      this.listOfInitalAfes = this.listOfAfes;
      this.listOfUwis = [];
      this.listOfCategories = [];

      this.listOfAfes.forEach((afe) => {
        let uwinum = '';
        if (afe.uwi != null) {
          uwinum += afe.uwi;
        }
        if (afe.uwi_2 != null) {
          uwinum += '-' + afe.uwi_2;
        }
        if (afe.uwi_3 != null) {
          uwinum += '-' + afe.uwi_3;
        }

        let uwiobj = {
          uwi: afe.uwi,
          label: uwinum,
        };
        if (this.listOfUwis.filter((x) => x.uwi == uwiobj.uwi).length === 0) {
          this.listOfUwis.push(uwiobj);
        }

        if (this.listOfCategories.indexOf(afe.category) === -1) {
          this.listOfCategories.push(afe.category);
        }
      });
    },
    async onSelectAfe() {
      this.listOfVendors = [];
      this.vendorSelection = [];
      this.listOfCategories = [];
      this.listOfUwis = [];

      let afenums = [];
      let projectids = [];

      this.afeSelection.forEach((afe) => {
        afenums.push(afe.afenum);
      });
      this.projectSelection.forEach((project) => {
        projectids.push(project.projectid);
      });

      this.displayBuildInvoiceButton = false;

      let payload = { afenum: afenums, projectid: projectids };
      await this.$axios
        .post('master_invoice/vendors/', payload)
        .then((response) => {
          this.listOfVendors = response.data.result;
        })
        .catch((error) => {
          this.$toasted.show('Error. ' + error.message, {
            type: 'error',
            duration: '3000',
          });
        });
    },
    onSelectVendor() {
      if (this.vendorSelection != null) {
        this.displayBuildInvoiceButton = true;
      } else {
        this.displayBuildInvoiceButton = false;
      }
    },

    async loadInvoices(displayViewAllInvoicesModal) {
      this.loading = true;
      let projectnums = [];
      let afenums = [];
      this.listOfInvoiceServices = [];
      this.tableDataInvoices = [];
      this.subtotal = 0;
      this.tax = 0;
      this.gst = 0;

      this.projectSelection.forEach((project) => {
        projectnums.push(project.projectid);
      });

      this.afeSelection.forEach((afe) => {
        afenums.push(afe.afenum);
      });

      let payload = {
        projectid: projectnums,
        afenum: afenums,
        vendor_company_name: this.vendorSelection.vendor_company_name,
      };

      await this.$axios
        .post('master_invoice/get/', payload)
        .then(async (response) => {
          this.listOfInvoiceServices = response.data.result;

          //  Filter based on date selection
          if (this.dateFilterRange != null) {
            let startDate = this.$moment(this.dateFilterRange.start).format(
              'YYYY-MM-DD'
            );
            let endDate = this.$moment(this.dateFilterRange.end).format(
              'YYYY-MM-DD'
            );

            this.listOfInvoiceServices = this.listOfInvoiceServices.filter(
              (line) => {
                const range = moments.range(startDate, endDate);
                const range1 = moments.range(line.start_date, line.end_date);
                const a = moments(line.start_date);
                const b = moments(line.end_date);

                if (
                  a.within(range) ||
                  b.within(range) ||
                  range1.overlaps(range)
                )
                  return true;
              }
            );
          }

          if (this.listOfInvoiceServices.length < 1) {
            this.$toasted.show(
              'There were no invoices within the specified criteria.',
              { type: 'error', duration: '3000' }
            );
            this.masterInvoiceModalData = [];
            this.$refs.master - invoice - modal.hide();
            return;
          }

          //  Snag an invoice from the first service line (Super hacky but whatever :) )
          await this.$axios
            .get(
              'master_invoice/invoice/get/' +
              this.listOfInvoiceServices[0].invoiceid
            )
            .then((response) => {
              this.invoice = response.data.result[0];
              this.invoice[
                'companyid'
              ] = this.listOfInvoiceServices[0].companyid;
            })
            .catch((error) => {
              this.$toasted.show('Error. ' + error.message, {
                type: 'error',
                duration: '3000',
              });
              return;
            });

          //  Generate a list of all invoices from uniq invoice ids
          let listOfInvoiceIds = [];
          this.lodash
            .uniqBy(this.listOfInvoiceServices, 'invoiceid')
            .forEach((inv) => {
              listOfInvoiceIds.push(inv.invoiceid);
            });
          await this.$axios
            .post('master_invoice/invoice/get/', {
              invoiceid: listOfInvoiceIds,
            })
            .then((response) => {
              this.listOfInvoices = response.data.result;
            })
            .catch((error) => {
              this.$toasted.show('Error. ' + error.message, {
                type: 'error',
                duration: '3000',
              });
            });

          //  Now get the mother company
          await this.$axios
            .get(
              '/master_invoice/company/' +
              this.listOfInvoiceServices[0].companyid
            )
            .then((response) => {
              this.mother_company_name = response.data.result.name;
            })
            .catch((error) => {
              this.$toasted.show('Error. ' + error.message, {
                type: 'error',
                duration: '3000',
              });
              return;
            });
          this.listOfInvoices.forEach((invoice) => {
            if (
              invoice.subtotal === undefined ||
              invoice.subtotal === 'undefined'
            ) {
              invoice.subtotal = 0;
            }

            if (
              invoice.subtotalWithoutDiscountAndGst === undefined ||
              invoice.subtotalWithoutDiscountAndGst === 'undefined'
            ) {
              invoice.subtotalWithoutDiscountAndGst = 0;
            }

            if (
              invoice.subtotalWithDiscount === undefined ||
              invoice.subtotalWithDiscount === 'undefined'
            ) {
              invoice.subtotalWithDiscount = 0;
            }

            if (
              invoice.totalWithDiscount === undefined ||
              invoice.totalWithDiscount === 'undefined'
            ) {
              invoice.totalWithDiscount = 0;
            }

            this.listOfInvoiceServices.forEach((service) => {
              if (invoice.invoiceid === service.invoiceid) {
                service.creation_date = this.$moment(
                  invoice.creation_date
                ).format('MMMM Do YYYY');
                service.accountant_paid = invoice.accountant_paid;
                service.invnum = invoice.invnum;
                //
                //  Calc the subtotal for each invoice
                //
                invoice.subtotalWithoutDiscountAndGst += this.calcLineTotalWithAfePercentageNoDiscountNoGst(
                  service
                );
                invoice.subtotalWithDiscount += this.calcLineTotalWithAfePercentage(
                  service
                ); // <== Dont forget to factor GST with this
                invoice.totalWithDiscount += this.calcLineTotalWithAfePercentage(
                  service
                ); // <== Includes discount and NOT gst

                invoice.subtotal += this.calcLineTotalWithAfePercentageNoDiscountNoGst(
                  service
                );
                //invoice.subtotal += (service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu);
              }
            });
          });
          //  Map the invoices data to a 2d array
          //  array[{services, name}], [services]
          //  1 dimention is "header" row
          //  2 dimention is the data rows onces header row is expanded
          const that = this;
          that.tableDataInvoices = that.lodash
            .chain(this.listOfInvoiceServices)
            .groupBy('name')
            .toPairs()
            .map(function (currentItem) {
              return that.lodash.zipObject(['name', 'services'], currentItem);
            })
            .value();
          let i = 0;
          this.tableDataInvoices.forEach((tableData) => {
            tableData.id = i++;
            tableData.type = tableData.services[0].type;
            tableData.uom = tableData.services[0].uom1;

            if (this.lodash.uniqBy(tableData.services, 'pu').length > 1) {
              tableData.pu = 'Mixed UOM';
            } else {
              tableData.pu =
                '$' +
                tableData.services[0].pu +
                '/' +
                tableData.services[0].uom1;
            }
            let quantity = 0;
            let discountSumLine = 0;
            let sumLine = 0;
            tableData.services.forEach((serviceEntry) => {
              // I added the multipication below to apply the AFE percentage to the quantities as well
              quantity +=
                serviceEntry.quantity * (serviceEntry.percentage / 100);

              discountSumLine += this.calcLineTotalWithAfePercentage(
                serviceEntry
              ); //  Factors in discount

              /* if(serviceEntry.discount != 0){
              discountSumLine = discountSumLine - (discountSumLine * serviceEntry.discount/100);
            } */

              sumLine +=
                serviceEntry.quantity *
                serviceEntry.pu *
                (serviceEntry.percentage / 100);
              serviceEntry.discountedPriceUnit = this._discount_price_per_unit(
                ((sumLine - discountSumLine) / sumLine) * 100,
                serviceEntry.pu
              );
            });
            tableData.quantity = quantity;
            tableData.calculatedDiscount =
              this.formatPrice(((sumLine - discountSumLine) / sumLine) * 100) +
              '%';
            tableData.totalwithoutdiscount = '$' + this.formatPrice(sumLine);
            tableData.totalwithdiscount =
              '$' + this.formatPrice(discountSumLine);
            tableData.discountedPriceUnit =
              '$' +
              this._discount_price_per_unit(
                ((sumLine - discountSumLine) / sumLine) * 100,
                tableData.services[0].pu
              );
          });

          //  Calc subtotal, tax and gst
          this.tableDataInvoices.forEach((tableData) => {
            this.subtotal += Number(
              tableData.totalwithdiscount
                .replaceAll(',', '')
                .replaceAll('$', '')
            );
          });
          //Add em up
          this.tax = this.subtotal * (this.invoice.tax_percentage / 100);
          this.total = this.subtotal + this.tax;

          //Format
          this.tax = this.formatPrice(this.tax);
        })
        .catch((error) => {
          this.$toasted.show('Error. ' + error.message, {
            type: 'error',
            duration: '3000',
          });
        });

      if (displayViewAllInvoicesModal) {
        this.masterInvoiceModalData = this.listOfInvoiceServices;
        this.masterInvoiceModalData = this.masterInvoiceModalData.filter(
          (invoice) => {
            return invoice.vid === this.vendorSelection.vid;
          }
        );
        this.masterInvoiceModalData.forEach((invoice) => {
          if (typeof invoice.creation_date_modified === 'undefined') {
            invoice.creation_date = this.$moment(invoice.creation_date).format(
              'MMMM Do YYYY'
            ); //format the date
            invoice.creation_date_modified = true;
          }
        });
      }

      this.loading = false;
    },

    clearDates() {
      this.dateFilterRange = null;
    },

    viewInvestigationTable() {
      this.filterSelection = {};
      this.filterSelection.xAxisSelection = 'AFE / PO';
      this.filterSelection.yAxisSelection = 'Line Item';
      this.filterSelection.dataSelection = 'Total With Discount';
    },

    viewAllInvoices() {
      /* this.masterInvoiceModalData = this.listOfInvoices;
        this.masterInvoiceModalData = this.masterInvoiceModalData.filter( invoice => {
          return invoice.vid === this.vendorSelection.vid;
        });
        this.masterInvoiceModalData.forEach(invoice => {
          console.log(invoice);
          if(typeof invoice.creation_date_modified === 'undefined'){
            invoice.creation_date = this.$moment(invoice.creation_date).format('MMMM Do YYYY'); //format the date
            invoice.creation_date_modified = true;
          }
        }); */
    },

    async getAndSetInvoice(service_line, status) {
      await this.$axios
        .get('master_invoice/invoice/get/' + service_line.invoiceid)
        .then((response) => {
          console.log(response.data.result[0]);
          let invoice = response.data.result[0];
          this.setInvoice(invoice, status);
          this.$refs.invoiceModal.show();
        })
        .catch((error) => {
          this.$toasted.show('Error. ' + error.message, {
            type: 'error',
            duration: '3000',
          });
        });
    },

    /* onFilterChange(clear){

      //  Clear filters
      if(clear){
        this.categoryFilterSelection = null;
        this.wellFilterSelection = null;
      }

      //  Restore inital list of AFEs
      this.listOfAfes = this.listOfInitalAfes;

      this.displayNotAfeFoundNotification = false;

      let hasCategoryFilter = this.categoryFilterSelection != null && this.categoryFilterSelection.length > 0;
      let hasWellFilter = this.wellFilterSelection != null && this.wellFilterSelection.length > 0;
      let afesFilteredByCategory = [];
      let afesFilteredByWell = [];

      //  Filter by category
      if(hasCategoryFilter){
        this.categoryFilterSelection.forEach(filterChoice => {
          afesFilteredByCategory = afesFilteredByCategory.concat(this.listOfAfes.filter( afe => {
            return afe.category ===  filterChoice;
          }));
        })
        console.log('===Filtering by category===');
        console.log('found ' + afesFilteredByCategory.length + ' afes');
        afesFilteredByCategory.forEach(afe => {
          console.log(afe.afenum);
        });
        console.log("\n");
      }

      //  Filter by well
      if(hasWellFilter){
        this.wellFilterSelection.forEach(filterChoice => {
          afesFilteredByWell = afesFilteredByWell.concat(this.listOfAfes.filter( afe => {
            let uwinum = "";
            if(afe.uwi!=null){
              uwinum += afe.uwi;
            }
            if(afe.uwi_2!=null){
              uwinum += '-' + afe.uwi_2;
            }
            if(afe.uwi_3!=null){
              uwinum += '-' + afe.uwi_3;
            }
            return uwinum ===  filterChoice;
          }));
        })
        console.log('===Filtering by well===');
        console.log('found ' + afesFilteredByWell.length + ' afes');
        afesFilteredByWell.forEach(afe => {
          console.log(afe.afenum);
        });
        console.log("\n");
      }

      //  Both filters
      if(hasWellFilter && hasCategoryFilter){
        console.log("===both filters===");
        console.log("lodash");
        console.dir(this.lodash.intersection(afesFilteredByCategory,afesFilteredByWell));
        this.listOfAfes = this.lodash.intersection(afesFilteredByCategory,afesFilteredByWell);
      //  Only well filter
      } else if (hasWellFilter && !hasCategoryFilter){
        this.listOfAfes = afesFilteredByWell;
      //  Only category filter
      } else if (!hasWellFilter && hasCategoryFilter) {
        this.listOfAfes = afesFilteredByCategory;
      }

      if(this.listOfAfes.length < 1){
        this.displayNotAfeFoundNotification = true;
      }

      console.log("\n\n\n");
    } */

    async updateInvoiceStatus(invoiceid) {
      await this.$axios
        .get('/master_invoice/invoice/get/' + invoiceid)
        .then((response) => {
          var refreshedInvoice = response.data.result[0];
          this.tableDataInvoices.forEach((line_group) => {
            line_group.services.forEach((line) => {
              if (line.invoiceid === invoiceid) {
                line.inv_passed = refreshedInvoice.inv_passed;
              }
            });
          });
          this.listOfInvoices.find((x) => {
            refreshedInvoice.invoiceid === x.invoiceid;
          }).inv_passed = refreshedInvoice.inv_passed;
          this.$refs.invoiceModal.hide();
        })
        .catch((error) => {
          console.log('error in updateInvoiceStatus ' + error);
        });
    },
  },
  computed: {
    groupProjects: function () {
      const that = this;
      var unique = that.lodash.uniqBy(this.listOfInvoiceServices, (v) =>
        [v.uwi, v.projectname].join()
      );

      var grouped = that.lodash
        .chain(unique)
        .groupBy('projectname')
        .toPairs()
        .map(function (currentItem) {
          return that.lodash.zipObject(['name', 'info'], currentItem);
        })
        .value();

      return grouped;
    },
    /*  subtotal: function(){
      return this.listOfInvoiceServices.reduce(function(total, item){
        return total + item.totalwithdiscount
      },0);
    }, */
  },
  mounted: async function () {
    await this.$axios
      .get('master_invoice/projects/')
      .then((response) => {
        this.listOfProjects = response.data.result;
      })
      .catch((error) => {
        this.$toasted.show('Error. ' + error.message, {
          type: 'error',
          duration: '3000',
        });
      });

    if (typeof this.propafes === 'undefined') {
      return;
    }
    this.loading = true;

    //  Get a list of all the projects and make the selectedproject based on the ID
    this.projectSelection = this.listOfProjects.filter((o) => {
      return o.projectid === this.propinvoice.projectid;
    });

    this.listOfAfes = this.propafes;
    if (this.propafes.length < 1) {
      this.afeSelection = this.lodash.uniqBy(
        this.listOfInvoiceServices,
        'afenum'
      );
    } else {
      this.afeSelection = this.lodash.uniqBy(this.propafes, 'afenum');
    }

    if (this.afeSelection.length < 1) {
      this.$toasted.show(
        'There is no master invoice data for this invoice (missing afe).',
        { type: 'error', duration: '3000' }
      );
      this.loading = false;
      return;
    }

    let afenums = [];
    this.afeSelection.forEach((afe) => {
      afenums.push(afe.afenum);
    });

    let payload = {
      projectid: this.projectSelection[0].projectid,
      afenum: afenums,
    };
    this.$axios
      .post('master_invoice/vendors/', payload)
      .then((response) => {
        this.listOfVendors = response.data.result;
        console.log('LIST OF VENDORS');
        console.log(this.listOfVendors);

        this.vendorSelection = this.listOfVendors.filter((o) => {
          return o.vid === this.propinvoice.vid;
        })[0];

        this.loadInvoices(false);
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.$toasted.show('Error. ' + error.message, {
          type: 'error',
          duration: '3000',
        });
      });
  },
};
</script>

<style lang="scss">
#rt-master-invoice-table div table thead tr th {
  background-color: #444;
  color: #fff;
  font-size: 1.2em;
}

#rt-master-invoice-table div table thead tr th:first-child {
  width: 50px;
}

#rt-master-invoice-table td {
  vertical-align: middle;
}

#rt-master-invoice-table .VueTables__child-row td {
  padding-right: 0 !important;
}

#rt-master-invoice-table-2 div table thead tr th {
  background-color: #444;
  color: #fff;
  font-size: 1.2em;
}

#rt-master-invoice-table-2 div table thead tr th:first-child {
  width: 350px;
}

#rt-master-invoice-table-2 td {
  vertical-align: middle;
}

#rt-master-invoice-table-2 .VueTables__child-row td {
  padding-right: 0 !important;
}

#loading-gif {
  margin: auto;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
}

.loadingclass {
  opacity: 0.3;
}

.rt-master-child-table {
  tbody {
    background-color: rgba(63, 233, 38, 0.09);
  }

  margin-left: 40px;

  .VuePagination {
    margin-top: 2em;
  }

  th {
    background: #7d7d7d !important;
    color: #fff !important;
    font-size: 1em !important;
  }

  th {
    width: inherit !important;
  }

  .VueTables__child-row>td {
    padding: 0;
  }
}

.view-button {
  min-width: auto !important;
  max-width: inherit !important;
  width: auto !important;
  color: #fff;
}

#invoiceModal {
  .modal-dialog {
    max-width: 1400px !important;
  }
}

#master-invoice-modal {
  .modal-dialog {
    max-width: 1400px !important;
  }
}

#investigate-modal {
  .modal-dialog {
    max-width: 1400px !important;
  }
}

.align-with-afes {
  margin-top: 16px;
}

#loading-gif {
  margin-top: 40px;
  margin-left: 40px;
}

.clear-dates {
  margin-bottom: 10px;
}

.align-button {
  margin-top: 10px;
}

.VueTables__child-row-toggler--open {
  line-height: 17px !important;
}

#rt-master-invoice-table {
  .parent th {
    background: #444;
    color: #fff;
  }

  .checkslots {
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 2px;
    font-size: 4em;
  }

  .table td {
    padding: 0.55rem;
  }

  .VueTables__heading {
    text-align: left;
  }

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .VueTables__search-field {
    display: flex;
  }

  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: left;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 18px;
    background: #4dbd74;
    display: block;
    margin: auto;
    text-align: center;
    padding: 6px !important;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
  }

  .VueTables__child-row-toggler--closed::before {
    content: '\f078' !important;
    font-weight: 400 !important;
    font-family: 'Font Awesome 5 Pro' !important;
  }

  .VueTables__child-row-toggler--open::before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400 !important;
    content: '\f077';
  }
}

.date-padding input {
  padding: 8px !important;
}
</style>
