var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn", class: { loadingclass: _vm.loading } },
    [
      _c(
        "div",
        [
          _vm.loading
            ? _c("rotate-square2", { attrs: { id: "loading-gif" } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c(
            "b-card",
            [
              _c("rotate-square2", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: { id: "loading-gif" }
              }),
              !_vm.loading
                ? _c("div", { staticClass: "row" }, [
                    _c("h4", [
                      _c("i", { staticClass: "fal fa-file-chart-line" }),
                      _vm._v(" All Invoice")
                    ]),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("v-client-table", {
                          attrs: {
                            data: _vm.listOfInvoices,
                            id: "rt-master-invoice-table-2",
                            columns: _vm.masterInvoiceModalColumns,
                            options: _vm.masterInvoiceModalOptions,
                            theme: _vm.theme
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "invoiceNumber",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(props.row.invnum) +
                                        "\n            "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "submissionDate",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            props.row.creation_date,
                                            "M/D/YYYY"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "subtotalWithoutDiscountAndGst",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n              $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            props.row
                                              .subtotalWithoutDiscountAndGst
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "subtotalWithDiscount",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n              $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            props.row.subtotalWithDiscount
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "totalWithDiscount",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n              $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            props.row.totalWithDiscount +
                                              (props.row.totalWithDiscount *
                                                props.row.tax_percentage) /
                                                100
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "status",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("InvoiceStatus", {
                                        attrs: {
                                          invoice: props.row,
                                          toggle: true
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "view",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    props.row.inv_passed == 0 ||
                                    props.row.inv_passed == 8
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.invoiceModal",
                                                modifiers: {
                                                  invoiceModal: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn-sm btn-blue",
                                            on: {
                                              click: function($event) {
                                                return _vm.setInvoice(
                                                  props.row,
                                                  1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Open Invoice\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.invoiceModal",
                                                modifiers: {
                                                  invoiceModal: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn-sm btn-blue",
                                            on: {
                                              click: function($event) {
                                                return _vm.setInvoice(
                                                  props.row,
                                                  0
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Open Invoice\n              "
                                            )
                                          ]
                                        )
                                  ])
                                }
                              }
                            ],
                            null,
                            false,
                            3495216775
                          )
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "invoiceModal",
          staticClass: "invoice-modal",
          attrs: { id: "invoiceModal", "hide-header": "", "ok-only": "" }
        },
        [
          _vm.$session.get("user") == "pm"
            ? _c("WTS-Invoice", {
                attrs: { fromexternal: true, dontRedirect: true },
                on: { onStatusUpdate: _vm.updateInvoiceStatus }
              })
            : _vm._e(),
          _vm.$session.get("user") == "wss"
            ? _c("WSS-Invoice", {
                attrs: { fromexternal: true, dontRedirect: true },
                on: { onStatusUpdate: _vm.updateInvoiceStatus }
              })
            : _vm._e(),
          _vm.$session.get("user") == "manager"
            ? _c("MNG-Invoice", {
                attrs: { fromexternal: true, dontRedirect: true },
                on: { onStatusUpdate: _vm.updateInvoiceStatus }
              })
            : _vm._e(),
          _vm.$session.get("user") == "accountant"
            ? _c("MNG-Invoice", {
                attrs: { fromexternal: true, dontRedirect: true },
                on: { onStatusUpdate: _vm.updateInvoiceStatus }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "investigate-modal",
          staticClass: "modal-dialog",
          attrs: { id: "investigate-modal", "hide-header": "", "ok-only": "" }
        },
        [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _vm.$session.get("user") == "pm"
                ? _c("WtsInvestigationTable", {
                    attrs: {
                      filterSelection: _vm.filterSelection,
                      masterInvoiceProjectSelection: _vm.projectSelection,
                      masterInvoiceAfeSelection: _vm.afeSelection,
                      masterInvoiceVendorSelection: _vm.vendorSelection
                    }
                  })
                : _vm._e(),
              _vm.$session.get("user") == "wss"
                ? _c("WssInvestigationTable", {
                    attrs: {
                      filterSelection: _vm.filterSelection,
                      masterInvoiceProjectSelection: _vm.projectSelection,
                      masterInvoiceAfeSelection: _vm.afeSelection,
                      masterInvoiceVendorSelection: _vm.vendorSelection
                    }
                  })
                : _vm._e(),
              _vm.$session.get("user") == "manager"
                ? _c("ManagerInvestigationTable", {
                    attrs: {
                      filterSelection: _vm.filterSelection,
                      masterInvoiceProjectSelection: _vm.projectSelection,
                      masterInvoiceAfeSelection: _vm.afeSelection,
                      masterInvoiceVendorSelection: _vm.vendorSelection
                    }
                  })
                : _vm._e(),
              _vm.$session.get("user") == "accountant"
                ? _c("AccountantInvestigationTable", {
                    attrs: {
                      filterSelection: _vm.filterSelection,
                      masterInvoiceProjectSelection: _vm.projectSelection,
                      masterInvoiceAfeSelection: _vm.afeSelection,
                      masterInvoiceVendorSelection: _vm.vendorSelection
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("b-card", [
        _c("h4", [
          _c("i", { staticClass: "fal fa-file-chart-line" }),
          _vm._v(" Master Invoice")
        ]),
        _c("hr"),
        _c("div", { staticClass: "row my-3" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("h5", [_vm._v("Select Project(s)")]),
              _c("multiselect", {
                attrs: {
                  "track-by": "projectid",
                  options: _vm.listOfProjects,
                  multiple: true,
                  "close-on-select": true,
                  label: "projectname"
                },
                on: {
                  input: function($event) {
                    return _vm.onSelectProject()
                  }
                },
                model: {
                  value: _vm.projectSelection,
                  callback: function($$v) {
                    _vm.projectSelection = $$v
                  },
                  expression: "projectSelection"
                }
              })
            ],
            1
          ),
          _vm.listOfAfes.length > 0
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("h5", [_vm._v("\n          Select AFE / PO\n          ")]),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.listOfAfes,
                      "track-by": "afenum",
                      multiple: true,
                      "close-on-select": false,
                      label: "afenum"
                    },
                    on: {
                      input: function($event) {
                        return _vm.onSelectAfe()
                      }
                    },
                    model: {
                      value: _vm.afeSelection,
                      callback: function($$v) {
                        _vm.afeSelection = $$v
                      },
                      expression: "afeSelection"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.afeSelection.length > 0 && _vm.listOfAfes.length > 0
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("h5", [_vm._v("Select Vendor")]),
                  _c("multiselect", {
                    attrs: {
                      multiple: false,
                      "track-by": "vid",
                      options: _vm.listOfVendors,
                      label: "vendor_company_name"
                    },
                    on: {
                      input: function($event) {
                        return _vm.onSelectVendor()
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "option__desc" }, [
                                _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    _vm._s(props.option.vendor_company_name) +
                                      " (" +
                                      _vm._s(props.option.lname) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      55717311
                    ),
                    model: {
                      value: _vm.vendorSelection,
                      callback: function($$v) {
                        _vm.vendorSelection = $$v
                      },
                      expression: "vendorSelection"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayNotAfeFoundNotification,
                  expression: "displayNotAfeFoundNotification"
                }
              ],
              staticClass: "col-md-4"
            },
            [
              _c("h5", { staticStyle: { color: "red" } }, [_vm._v("Warning")]),
              _vm._v(
                "\n        No Afe's found for the selected filtering criteria. "
              ),
              _c("br"),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-sm float mt-3",
                  on: {
                    click: function($event) {
                      return _vm.onFilterChange(true)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-eraser" }),
                  _vm._v(" Clear Filters\n        ")
                ]
              )
            ]
          )
        ]),
        _vm.displayAfeFilters
          ? _c("div", { staticClass: "row my-1" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("h5", [_vm._v("Filter AFE(s) / PO(s)")])
              ]),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("h6", [_vm._v("By Category")]),
                  _c("multiselect", {
                    attrs: {
                      multiple: true,
                      placeholder: "Category",
                      "close-on-select": true,
                      options: _vm.listOfCategories
                    },
                    on: {
                      input: function($event) {
                        return _vm.onFilterChange(false)
                      }
                    },
                    model: {
                      value: _vm.categoryFilterSelection,
                      callback: function($$v) {
                        _vm.categoryFilterSelection = $$v
                      },
                      expression: "categoryFilterSelection"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("h6", [_vm._v("By Well")]),
                  _c("multiselect", {
                    attrs: {
                      multiple: true,
                      "track-by": "uwi",
                      label: "label",
                      options: _vm.listOfUwis
                    },
                    on: {
                      input: function($event) {
                        return _vm.onFilterChange(false)
                      }
                    },
                    model: {
                      value: _vm.wellFilterSelection,
                      callback: function($$v) {
                        _vm.wellFilterSelection = $$v
                      },
                      expression: "wellFilterSelection"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.displayBuildInvoiceButton && _vm.listOfAfes.length > 0,
                expression: "displayBuildInvoiceButton && listOfAfes.length > 0"
              }
            ],
            staticClass: "row"
          },
          [_c("div", { staticClass: "col-md-12" }, [_c("hr")])]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.displayBuildInvoiceButton && _vm.listOfAfes.length > 0,
                expression: "displayBuildInvoiceButton && listOfAfes.length > 0"
              }
            ],
            staticClass: "row mt-3",
            staticStyle: { "margin-bottom": "3rem" }
          },
          [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("h5", [
                  _vm._v("\n          Filter By Date (optional)\n          "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info btn-sm float float-right clear-dates",
                      on: {
                        click: function($event) {
                          return _vm.clearDates()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-eraser" }),
                      _vm._v(" Clear Dates\n          ")
                    ]
                  )
                ]),
                _c("v-date-picker", {
                  staticClass: "vc-calendar date-padding",
                  attrs: {
                    formats: _vm.formats,
                    mode: "range",
                    "show-caps": ""
                  },
                  model: {
                    value: _vm.dateFilterRange,
                    callback: function($$v) {
                      _vm.dateFilterRange = $$v
                    },
                    expression: "dateFilterRange"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-md-8 text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary animated rubberBand mt-3",
                  on: {
                    click: function($event) {
                      return _vm.loadInvoices(false)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-file-invoice-dollar" }),
                  _vm._v(" Generate Master Invoice\n        ")
                ]
              )
            ])
          ]
        ),
        _vm.listOfInvoiceServices.length > 0 && !_vm.loading
          ? _c(
              "div",
              { staticClass: "mt-3 clearfix mb-4" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _vm.invoice
                          ? _c("VendorDetails", {
                              attrs: { invoice: _vm.invoice }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _vm.invoice && _vm.mother_company_name
                          ? _c("CompanyDetails", {
                              attrs: {
                                invoice: _vm.invoice,
                                company_name: _vm.mother_company_name
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { md: "4" } }, [
                      _c("div", [
                        _c("h4", { staticClass: "mb-3" }, [_vm._v("Details")]),
                        _c("hr"),
                        _c(
                          "ul",
                          {
                            staticClass: "fa-ul",
                            staticStyle: { "list-style": "none" }
                          },
                          _vm._l(_vm.groupProjects, function(afe) {
                            return _c(
                              "li",
                              { staticStyle: { "margin-bottom": "1em" } },
                              [
                                _c("span", { staticClass: "fa-li" }, [
                                  _c("i", {
                                    staticClass: "fa fa-project-diagram"
                                  })
                                ]),
                                _vm._v(
                                  _vm._s(afe.name) + "\n\n                "
                                ),
                                _vm._l(afe.info, function(item) {
                                  return _c(
                                    "ul",
                                    {
                                      staticClass: "fa-ul",
                                      staticStyle: {
                                        "margin-left": "1.5em",
                                        "margin-bottom": "0.25em"
                                      }
                                    },
                                    [
                                      _c("li", [
                                        _c("span", { staticClass: "fa-li" }, [
                                          _c("i", { staticClass: "fa fa-tint" })
                                        ]),
                                        _vm._v(
                                          "UWI:\n                    " +
                                            _vm._s(item.uwi) +
                                            "\n                  "
                                        )
                                      ]),
                                      _c("li", [
                                        _c("span", { staticClass: "fa-li" }, [
                                          _c("i", {
                                            staticClass: "fa fa-hashtag"
                                          })
                                        ]),
                                        _vm._v(
                                          "License Number: " +
                                            _vm._s(item.ln) +
                                            "\n                  "
                                        )
                                      ]),
                                      _c("li", [
                                        _c("span", { staticClass: "fa-li" }, [
                                          _c("i", {
                                            staticClass: "fa fa-location"
                                          })
                                        ]),
                                        _vm._v(
                                          "Surface Location: " +
                                            _vm._s(item.lsdsurface) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.listOfInvoiceServices.length > 0 && !_vm.loading
          ? _c(
              "div",
              [
                _c("h4", [
                  _c("i", { staticClass: "fas fa-sticky-note" }),
                  _vm._v(" Sub Invoice Details")
                ]),
                _c("hr"),
                _c("div", [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "25px",
                          "margin-left": "20px"
                        }
                      },
                      [
                        _c("strong", [_vm._v("Subtotal:")]),
                        _vm._v(" $" + _vm._s(_vm.formatPrice(_vm.subtotal)))
                      ]
                    ),
                    _c("span", { staticStyle: { "margin-right": "25px" } }, [
                      _c("strong", [
                        _vm._v(
                          "GST (" + _vm._s(_vm.invoice.tax_percentage) + "%):"
                        )
                      ]),
                      _vm._v(" $" + _vm._s(_vm.tax))
                    ]),
                    _c("span", { staticStyle: { "margin-right": "25px" } }, [
                      _c("strong", [_vm._v("Total:")]),
                      _vm._v(" $" + _vm._s(_vm.formatPrice(_vm.total)))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.investigate-modal",
                              modifiers: { "investigate-modal": true }
                            }
                          ],
                          staticClass: "btn-primary",
                          staticStyle: {
                            padding: "5px",
                            "border-radius": "0.25rem",
                            margin: "15px 21px 15px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.viewInvestigationTable()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-eye" }),
                          _vm._v(" Investigate More\n          ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.master-invoice-modal",
                              modifiers: { "master-invoice-modal": true }
                            }
                          ],
                          staticClass: "btn-info",
                          staticStyle: {
                            padding: "5px",
                            "border-radius": "0.25rem",
                            margin: "15px 21px 15px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.viewAllInvoices()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-analytics" }),
                          _vm._v(" View all invoices\n          ")
                        ]
                      )
                    ]
                  )
                ]),
                _c("hr"),
                _c("v-client-table", {
                  attrs: {
                    data: _vm.tableDataInvoices,
                    id: "rt-master-invoice-table",
                    columns: _vm.columnsInvoices,
                    options: _vm.options,
                    theme: _vm.theme
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "child_row",
                        fn: function(props) {
                          return [
                            _c("v-client-table", {
                              staticClass:
                                "rt-table-striped rt-master-child-table",
                              attrs: {
                                data: props.row.services,
                                columns: _vm.childColumnsInvoices,
                                options: _vm.childOptions,
                                theme: _vm.theme,
                                id: "master-invoice"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "pu",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              $" +
                                            _vm._s(props.row.pu) +
                                            "/" +
                                            _vm._s(props.row.uom1) +
                                            "\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "discountedPriceUnit",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                props.row.discountedPriceUnit
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "quantity",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(props.row.quantity) +
                                            "\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "service_discount",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                props.row.discount
                                              )
                                            ) +
                                            "%\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "total",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              $" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                _vm.calcLineTotal(props.row)
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "afe_percentage",
                                    fn: function(props) {
                                      return _c("div", {}, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                props.row.percentage
                                              )
                                            ) +
                                            "%\n            "
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "inv_passed",
                                    fn: function(props) {
                                      return _c(
                                        "div",
                                        { staticStyle: { padding: "4px" } },
                                        [
                                          _c("InvoiceStatus", {
                                            attrs: { invoice: props.row }
                                          }),
                                          props.row.inv_passed == 0 ||
                                          props.row.inv_passed == 8
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-sm btn-blue",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getAndSetInvoice(
                                                        props.row,
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(props.row.invnum) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-sm btn-blue",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getAndSetInvoice(
                                                        props.row,
                                                        0
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(props.row.invnum) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3495265292
                  )
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }