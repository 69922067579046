<template>
  <div class="animated fadeIn">
    <!-- THIS MODAL IS TO SEE THE MASTER INVOICE INVOICES -->
    <b-modal
      id="master-invoice-modal"
      ref="master-invoice-modal"
      class="modal-dialog"
      hide-header
      ok-only
    >
      <b-card>
        <div class="row">
          <div class="col-md-12">
            <Master-Invoice
              v-bind:propafes="this.afes"
              v-bind:propinvoice="this.invoice"
            >
            </Master-Invoice>
          </div>
        </div>
      </b-card>
    </b-modal>

    <b-card class="clearfix">
      <InvoiceHeader
        v-bind:invoice="invoice"
        v-bind:vwref="vwref"
        v-bind:serviceData="serviceData"
        v-bind:afes="afes"
        v-bind:currency="currency"
        v-bind:tax="tax"
        v-bind:company_name="company_name"
      ></InvoiceHeader>
      <div class="mt-2 invoice-status">
        <InvoiceStatus :invoice="invoice" :toggle="false"></InvoiceStatus>
      </div>
      <div>
        <b-button
          v-if="!fromexternal"
          v-b-modal.master-invoice-modal
          class="pull-right pl-3 btn-sm font-weight-bold"
          variant="primary"
          ><span class="mt-2 d-none d-sm-inline mr-1">View Master Invoice </span
          ><i class="fas fa-search"></i
        ></b-button>
      </div>
      <b-row class="mt-3 clearfix mb-4">
        <b-col>
          <VendorDetails v-bind:invoice="invoice"></VendorDetails>
        </b-col>
        <b-col>
          <CompanyDetails
            v-bind:invoice="invoice"
            v-bind:company_name="company_name"
          ></CompanyDetails>
        </b-col>
      </b-row>
      <b-row>
        <InvoiceDetails
          v-bind:toggleEditVIN="true"
          v-bind:invoice="invoice"
          v-bind:userData="userData"
          v-on:removeAll="removeAll()"
          v-bind:toggleVselect="true"
          v-bind:vwref="vwref"
          v-bind:uwiData="uwiData"
          v-bind:serviceData="serviceData"
          v-bind:afes="afes"
        ></InvoiceDetails>
      </b-row>

      <h3 class="d-inline-block">Services</h3>
      <button
        v-if="![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)"
        type="button"
        class="btn btn-sm btn-danger float-right"
        @click="removeAll()"
        name="button"
      >
        <strong>Remove All Invoice Codings <i class="fa fa-times"></i></strong>
      </button>
      <button
        type="button"
        v-show="isSortingEnabled"
        class="btn btn-sm btn-warning mr-2 float-right"
        @click="disableSorting()"
        name="button"
      >
        <strong>Disable Sorting</strong>
      </button>
      <table id="Service-table">
        <thead>
          <tr id="Service-table-tr">
            <th style="background-color: #464646; cursor: pointer">
              Service
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('name', true)"
                :style="{
                  color:
                    sortedValue[0] === 'name' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('name', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'name' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Code
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('type', true)"
                :style="{
                  color:
                    sortedValue[0] === 'type' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('type', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'type' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Start - End Date
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('dates', true)"
                :style="{
                  color:
                    sortedValue[0] === 'dates' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('dates', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'dates' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              UOM
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('uom', true)"
                :style="{
                  color:
                    sortedValue[0] === 'uom' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('uom', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'uom' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Price/Unit
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('pu', true)"
                :style="{
                  color:
                    sortedValue[0] === 'pu' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('pu', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'pu' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Discounted Price/unit
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('discounted_price_per_unit', true)"
                :style="{
                  color:
                    sortedValue[0] === 'discounted_price_per_unit' &&
                    sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('discounted_price_per_unit', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'discounted_price_per_unit' &&
                    !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Quantity
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('quantity', true)"
                :style="{
                  color:
                    sortedValue[0] === 'quantity' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('quantity', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'quantity' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Discount(%)
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('discount', true)"
                :style="{
                  color:
                    sortedValue[0] === 'discount' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('discount', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'discount' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Total
              <i
                class="fas fa-arrow-up mr-1"
                @click="sortData('total', true)"
                :style="{
                  color:
                    sortedValue[0] === 'total' && sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
              <i
                @click="sortData('total', false)"
                class="fas fa-arrow-down"
                :style="{
                  color:
                    sortedValue[0] === 'total' && !sortedValue[1]
                      ? 'cornflowerblue !important'
                      : 'white',
                }"
              ></i>
            </th>
            <th style="background-color: #464646; cursor: pointer">
              Applied Taxes
            </th>
            <th
              style="background-color: #464646"
              v-if="![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)"
            >
              Action
            </th>
          </tr>
        </thead>

        <tbody v-for="service in unassigned">
          <tr
            :draggable="![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)"
            @dragover.prevent="dragOver($event, service, service.isflexible)"
            @drop="serviceOnDrop(service, null)"
            @dragstart="afeDragStart(null, service)"
            id="Service-table-tr"
            :class="{
              highlightService: highlightServices.includes(service.vsid),
            }"
            v-bind:style="{
              backgroundColor:
                service.isflexible !== 'N' ? 'cornsilk !important' : '#F8F8FF',
            }"
            style="cursor: pointer"
          >
            <td>
              {{ service.name }}
            </td>
            <td>
              <span>{{ service.type }}</span>
            </td>
            <td>
              {{ service.start_date | moment('MM/DD/YYYY') }} -
              {{ service.end_date | moment('MM/DD/YYYY') }} <br /><span
                style="font-size: 10px"
                >({{
                  parseInt(
                    $moment(service.end_date).diff(
                      $moment(service.start_date),
                      'days'
                    )
                  ) + 1
                }}
                Days)</span
              >
            </td>
            <td>
              <span>{{ service.uom }}</span>
            </td>
            <td>
              <span>${{ formatPrice(service.pu) }}</span>
            </td>

            <td>
              <span>
                {{
                  service.discount
                    ? '$' +
                      _discount_price_per_unit(service.discount, service.pu)
                    : service.name
                    ? '$' + formatPrice(service.pu)
                    : null
                }}
              </span>
            </td>

            <td>
              <span>{{ parseFloat(service.quantity) }}</span>
              <span
                class="ml-1"
                v-if="
                  /day|Day/.test(service.uom) &&
                    $moment(service.end_date).diff(
                      $moment(service.start_date),
                      'days'
                    ) +
                      1 !=
                      service.quantity
                "
              >
                <i
                  v-b-tooltip.hover
                  :title="
                    'Quantity does not equal sum between start and end dates (' +
                      (parseInt(
                        $moment(service.end_date).diff(
                          $moment(service.start_date),
                          'days'
                        )
                      ) +
                        1) +
                      ')!'
                  "
                  class="fa fa-exclamation-triangle"
                ></i>
              </span>
            </td>
            <td>
              <span>{{ formatPrice(service.discount) }}%</span>
            </td>
            <td>
              <span
                >${{
                  formatPrice(
                    service.quantity * service.pu -
                      (service.discount / 100) * service.quantity * service.pu
                  )
                }}</span
              >
            </td>
            <td>
              <b-button
                size="sm"
                variant="success"
                style="width: max-content;"
                @click="openServiceTax(service)"
                >Applied Taxes
              </b-button>
            </td>
            <td
              style="width: 100px"
              v-if="![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)"
            >
              <b-button
                variant="success"
                class="add-coding"
                size="sm"
                :disabled="disableEdits == 1"
                v-on:click="addNewUnassignedAfeLine(service, false)"
                >Add Coding</b-button
              >
            </td>
          </tr>
          <tr
            :style="{
              display:
                service.comment.replace(/\s/g, '').length > 0
                  ? 'contents'
                  : 'none',
            }"
          >
            <td colspan="100%">
              <span>
                <textarea
                  class="form-control mb-3 d-block mt-3"
                  readonly
                  placeholder="Add comments"
                  v-model="service.comment"
                ></textarea>
              </span>
            </td>
          </tr>
          <tr
            :class="{ createAfe: service != createdUnassignedServiceLine }"
            :style="{
              display:
                service != createdUnassignedServiceLine ? 'none' : 'table-row',
            }"
          >
            <td colspan="100%" id="Service-table-child">
              <table class="table">
                <thead>
                  <tr id="Service-table-tr">
                    <th style="font-size: 11px !important; font-weight: revert">
                      AFE or Cost Center / Well
                    </th>
                    <th style="font-size: 11px !important; font-weight: revert">
                      Cost Code Chain
                    </th>
                    <th style="font-size: 11px !important; font-weight: revert">
                      Cost Code or coding Percentage %
                      <i
                        class="fa fa-question-circle"
                        v-b-tooltip.hover
                        title="AFE or Cost Code or coding Percentage"
                      ></i>
                    </th>
                    <th style="font-size: 11px !important; font-weight: revert">
                      subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="display: flex">
                      <v-select
                        :clearable="false"
                        style="width: 345px"
                        v-if="service == createdUnassignedServiceLine"
                        v-model="createAfenum"
                        :label="'afenum'"
                        :options="AfeData"
                        :value="afenum"
                        :selectable="
                          (option) =>
                            option.afenum !=
                            '========================================'
                        "
                        id="v-select1"
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-select
                        :clearable="false"
                        style="width: 345px"
                        :options="paginated"
                        :label="'costcodechainid'"
                        :filterable="false"
                        v-model="CC_data"
                        @search="(query) => (search = query)"
                        :selectable="
                          (option) => ![-2, -3].includes(option.costcodechainid)
                        "
                        id="v-select1"
                      >
                        <template v-slot:option="option">
                          <span
                            style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -2"
                            >==================================</span
                          >
                          <span
                            style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -3"
                            >==================================</span
                          >
                          <span
                            v-if="![-2, -3].includes(option.costcodechainid)"
                            >{{ option.costcode1 }}-{{ option.costcode2 }}-{{
                              option.costcode3
                            }}</span
                          >
                        </template>
                        <template
                          #selected-option="{
                            costcode1,
                            costcode2,
                            costcode3,
                            option,
                            deselect,
                            disabled,
                          }"
                        >
                          <span
                            >{{ costcode1 }}-{{ costcode2 }}-{{
                              costcode3
                            }}</span
                          >
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <input
                        type="number"
                        class="rt-percent"
                        step="any"
                        v-model="percentage"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="rt-percent form-control mr-2"
                        readonly
                        :value="
                          formatPrice(
                            Number(service.pu) * (Number(percentage) / 100)
                          )
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div id="afe-filter">
                <b-button
                  @mouseover="highlightService([service])"
                  @mouseleave="removeHighlightService()"
                  variant="success"
                  size="sm"
                  class="mr-3"
                  v-on:click="addAfeUnassignedLine(service)"
                  >Add <i class="ml-2 fa fa-plus-circle"></i
                ></b-button>
                <b-button
                  @mouseover="highlightService(serviceData)"
                  @mouseleave="removeHighlightService()"
                  variant="success"
                  size="sm"
                  class="mr-3"
                  v-on:click="addAfeToAllInvoiceServices(false)"
                  >Add To All<i class="fa fa-plus-circle ml-2"></i
                ></b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  v-on:click="cancelAfe()"
                  v-b-tooltip.hover
                  title="AFE or Cost Center / Well Cancel"
                  >Cancel<i class="ml-2 fa fa-times-circle"></i
                ></b-button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-for="(afe, index) in merged">
          <tr
            :class="{
              isTop: service.isTop,
              highlightService: highlightServices.includes(service.vsid),
            }"
            @dragover.prevent="
              dragOver($event, service, service.serviceid.isflexible)
            "
            id="Service-table-tr"
            @drop="serviceOnDrop(service, null)"
            style="
              color: white !important;
              border-left: 1.02px solid red;
              border-right: 1.02px solid red;
            "
            v-for="(service, serviceIndex) in afe"
            v-bind:style="{
              backgroundColor:
                service.isflexible !== 'N'
                  ? !afePercentage.includes(afe[0].vsid)
                    ? 'darkslategrey'
                    : '#ff4d4d'
                  : !afePercentage.includes(afe[0].vsid)
                  ? '#484848'
                  : '#ff4d4d',
            }"
          >
            <td>
              <span>{{ service.name }}</span>
            </td>
            <td>
              <span>{{ service.type }}</span>
            </td>
            <td>
              {{ service.start_date | moment('MM/DD/YYYY') }} -
              {{ service.end_date | moment('MM/DD/YYYY') }} <br /><span
                style="font-size: 10px"
                >({{
                  parseInt(
                    $moment(service.end_date).diff(
                      $moment(service.start_date),
                      'days'
                    )
                  ) + 1
                }}
                Days)</span
              >
            </td>
            <td>
              <span>{{ service.uom }}</span>
            </td>
            <td>
              <span>${{ formatPrice(service.pu) }}</span>
            </td>

            <td>
              <span>
                {{
                  service.discount
                    ? '$' +
                      _discount_price_per_unit(service.discount, service.pu)
                    : service.name
                    ? '$' + formatPrice(service.pu)
                    : null
                }}
              </span>
            </td>

            <td>
              <span>{{ parseFloat(service.quantity) }}</span>
              <span
                class="ml-1"
                v-if="
                  /day|Day/.test(service.uom) &&
                    $moment(service.end_date).diff(
                      $moment(service.start_date),
                      'days'
                    ) +
                      1 !=
                      service.quantity
                "
              >
                <i
                  v-b-tooltip.hover
                  :title="
                    'Quantity does not equal sum between start and end dates (' +
                      (parseInt(
                        $moment(service.end_date).diff(
                          $moment(service.start_date),
                          'days'
                        )
                      ) +
                        1) +
                      ')!'
                  "
                  class="fa fa-exclamation-triangle"
                ></i>
              </span>
            </td>
            <td>
              <span>{{ formatPrice(service.discount) }}%</span>
            </td>
            <td>
              <span
                >${{
                  formatPrice(
                    service.quantity * service.pu -
                      (service.discount / 100) * service.quantity * service.pu
                  )
                }}</span
              >
            </td>
            <td>
              <b-button
                size="sm"
                variant="success"
                style="width: max-content;"
                @click="openServiceTax(service)"
                >Applied Taxes
              </b-button>
            </td>
            <td v-if="![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)">
              <b-button
                variant="success"
                class="add-coding"
                size="sm"
                v-on:click="addNewAfeLine(afe, service)"
                :disabled="disableEdits == 1"
                >Add Coding</b-button
              >
            </td>
          </tr>
          <tr :style="{ display: afe[0].comment ? 'contents' : 'none' }">
            <td
              colspan="100%"
              style="
                border-left: 1.02px solid red;
                border-right: 1.02px solid red;
              "
            >
              <textarea
                class="form-control mb-3 d-block mt-3"
                readonly
                placeholder="Add comments"
                v-model="afe[0].comment"
              ></textarea>
            </td>
          </tr>
          <tr v-show="afe[0].show_afe || afe === createdServiceLine">
            <td colspan="100%" id="Service-table-child">
              <table class="table no-bg afenum mb-0">
                <thead
                  style="
                    border-left: 1.02px solid red;
                    border-right: 1.02px solid red;
                  "
                >
                  <tr>
                    <th><strong>AFE or Cost Center / Well</strong></th>
                    <th><strong> Cost Code Chain </strong></th>
                    <th>
                      <strong> Coding Percentage % </strong>
                    </th>
                    <th><strong>subtotal</strong></th>
                    <th id="editAfe">
                      <i
                        v-if="
                          ![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
                        "
                        :disabled="disableEdits == 1"
                        v-on:click="removeAllAfe(afe[0])"
                        style="
                          cursor: pointer;
                          margin-left: 9px;
                          font-size: medium;
                          color: red;
                          margin-top: 8px;
                        "
                        class="fas fa-minus-circle"
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    id="AfeDragStart"
                    :draggable="
                      ![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
                    "
                    @dragover.prevent="dragOver(afe[0], line)"
                    @drop="serviceOnDrop(afe[0], line)"
                    @dragstart="afeDragStart(line, afe[0])"
                    v-for="(line, aIndex) in afe[0].afes"
                    :class="{
                      editingAfe: line == editedAfeLine,
                      afeBorder:
                        afe[0].show_afe && aIndex === afe[0].afes.length - 1,
                    }"
                    v-show="afe[0].show_afe"
                    style="
                      border-left: 1.02px solid red;
                      border-right: 1.02px solid red;
                    "
                  >
                    <td>
                      <div
                        class="view"
                        @click="editAfeLine(line, index, aIndex, afes, afe)"
                      >
                        <i class="fa fa-chevron-circle-right"></i>
                        {{ line.afenum }}
                      </div>
                      <div class="edit">
                        <v-select
                          :clearable="false"
                          v-if="line == editedAfeLine"
                          v-model="line.afenum"
                          style="width: 345px"
                          label="afenum"
                          :options="serviceChainData"
                          :selectable="
                            (option) =>
                              option.afenum !=
                              '========================================'
                          "
                          :value="afenum"
                          id="v-select1"
                        ></v-select>
                      </div>
                    </td>
                    <td>
                      <div
                        class="view"
                        @click="editAfeLine(line, index, aIndex, afes, afe)"
                      >
                        {{ line.ccone_code }} -- {{ line.cctwo_code }} --
                        {{ line.ccthree_code }}
                      </div>
                      <div class="edit">
                        <v-select
                          :clearable="false"
                          :options="afeAttachedCostCodes"
                          v-if="line == editedAfeLine"
                          style="width: 345px"
                          :filterable="false"
                          :label="'costcodechainid'"
                          v-model="CC_data"
                          @search="(query) => (search = query)"
                          :selectable="
                            (option) =>
                              ![-2, -3].includes(option.costcodechainid)
                          "
                          id="v-select1"
                        >
                          <template v-slot:option="option">
                            <span
                              style="color: black; padding: 5px"
                              v-if="option.costcodechainid == -2"
                              >==================================</span
                            >
                            <span
                              style="color: black; padding: 5px"
                              v-if="option.costcodechainid == -3"
                              >==================================</span
                            >
                            <span
                              v-if="![-2, -3].includes(option.costcodechainid)"
                              >{{ option.costcode1 }}-{{ option.costcode2 }}-{{
                                option.costcode3
                              }}</span
                            >
                          </template>
                          <template
                            #selected-option="{
                              costcode1,
                              costcode2,
                              costcode3,
                              option,
                              deselect,
                              disabled,
                            }"
                          >
                            <span
                              >{{ costcode1 }}-{{ costcode2 }}-{{
                                costcode3
                              }}</span
                            >
                          </template>
                        </v-select>
                      </div>
                    </td>

                    <td>
                      <div
                        class="view"
                        @click="editAfeLine(line, index, aIndex, afes, afe)"
                      >
                        {{ line.percentage }}
                      </div>
                      <div class="edit">
                        <input
                          type="number"
                          class="rt-percent"
                          step="any"
                          v-model="percentage"
                        />
                      </div>
                    </td>
                    <td id="editAfe">
                      <input
                        type="text"
                        @click="editAfeLine(line, index, aIndex, afes, afe)"
                        class="rt-percent form-control view"
                        readonly
                        :value="
                          formatPrice(
                            Number(afe[0].combined_total) *
                              (Number(line.percentage) / 100)
                          )
                        "
                      />
                      <input
                        type="text"
                        class="rt-percent form-control edit"
                        readonly
                        :value="
                          formatPrice(
                            Number(afe[0].combined_total) *
                              (Number(percentage) / 100)
                          )
                        "
                      />
                      <b-overlay
                        :show="editAfe == 0"
                        v-if="line == editedAfeLine"
                        rounded="sm"
                        class="mt-3 d-flex justify-content-end"
                      >
                        <span
                          v-on:click="
                            editAfe = 0;
                            editAddAfeData(line, afe, index, aIndex, afes);
                          "
                          class="mr-3 edit"
                          style="color: green; font-size: medium"
                        >
                          <b
                            ><i
                              style="font-size: x-large"
                              class="fas fa-check-circle"
                            ></i
                          ></b>
                        </span>
                        <span
                          style="color: #f74f4f"
                          class="edit"
                          v-if="line == editedAfeLine"
                          v-on:click="
                            cancelEditAfe(line, afe, index, aIndex, afes)
                          "
                          v-b-tooltip.hover
                          title="AFE or Cost Center / Well Cancel"
                          ><i
                            style="font-size: x-large"
                            class="fa fa-times-circle"
                          ></i
                        ></span>
                      </b-overlay>
                    </td>
                    <td
                      class="view"
                      id="editAfe"
                      v-if="
                        ![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
                      "
                    >
                      <i
                        @click="deleteServiceAfe(line, afe[0])"
                        style="
                          cursor: pointer;
                          margin-left: 9px;
                          font-size: medium;
                          color: red;
                          margin-top: 8px;
                        "
                        class="fas fa-minus-circle"
                      ></i>
                    </td>
                  </tr>
                  <tr
                    :class="{ createAfe: afe != createdServiceLine }"
                    class="rt-wss-create-afe"
                  >
                    <td>
                      <div class="show">
                        <v-select
                          :clearable="false"
                          v-if="afe == createdServiceLine"
                          v-model="createAfenum"
                          label="afenum"
                          style="width: 345px"
                          :options="createAfeData"
                          :value="afenum"
                          :selectable="
                            (option) =>
                              option.afenum !=
                              '========================================'
                          "
                          id="v-select1"
                        ></v-select>
                      </div>
                    </td>
                    <td>
                      <div class="show">
                        <v-select
                          :clearable="false"
                          :options="paginated"
                          style="width: 345px"
                          :filterable="false"
                          v-model="CC_data"
                          :label="'costcodechainid'"
                          @search="(query) => (search = query)"
                          id="v-select1"
                        >
                          <template v-slot:option="option">
                            <span>{{ option.costcode1 }}</span>
                            <span>-{{ option.costcode2 }}</span>
                            <span>-{{ option.costcode3 }}</span>
                          </template>
                          <template
                            #selected-option="{
                              costcode1,
                              costcode2,
                              costcode3,
                              option,
                              deselect,
                              multiple,
                              disabled,
                            }"
                          >
                            <span>{{ costcode1 }}</span>
                            <span>-{{ costcode2 }}</span>
                            <span>-{{ costcode3 }}</span>
                          </template>
                        </v-select>
                      </div>
                    </td>
                    <td>
                      <div class="show">
                        <input
                          type="number"
                          class="rt-percent"
                          step="any"
                          v-model="percentage"
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="rt-percent form-control view"
                        readonly
                        :value="
                          formatPrice(
                            Number(
                              afe[0].quantity * afe[0].pu -
                                (afe[0].discount / 100) *
                                  afe[0].quantity *
                                  afe[0].pu
                            ) *
                              (Number(percentage) / 100)
                          )
                        "
                      />
                    </td>
                  </tr>
                  <tr
                    :class="{ createAfe: afe != createdServiceLine }"
                    :style="{
                      display: afe != createdServiceLine ? 'none' : 'revert',
                    }"
                  >
                    <td colspan="100%">
                      <div id="afe-filter">
                        <b-button
                          @mouseover="highlightService([afe[0]])"
                          @mouseleave="removeHighlightService()"
                          variant="success"
                          size="sm"
                          class="mr-3"
                          v-on:click="addAfeLine(afe, line)"
                          >Add <i class="ml-2 fa fa-plus-circle"></i
                        ></b-button>
                        <b-button
                          @mouseover="highlightService(serviceData)"
                          @mouseleave="removeHighlightService()"
                          variant="success"
                          size="sm"
                          class="mr-3"
                          v-on:click="addAfeToAllInvoiceServices(false)"
                          >Add To All<i class="fa fa-plus-circle ml-2"></i
                        ></b-button>
                        <b-button
                          @mouseover="
                            highlightService(
                              serviceData.filter((val) =>
                                afe[0].same_coding.includes(val.vsid)
                              )
                            )
                          "
                          @mouseleave="removeHighlightService()"
                          variant="warning"
                          size="sm"
                          class="mr-3"
                          v-on:click="addAfeToAllInvoiceServices(true, afe[0])"
                          >Add To Group <i class="mr-3 fa fa-plus-circle"></i
                        ></b-button>

                        <b-button
                          variant="danger"
                          size="sm"
                          v-on:click="cancelAfe()"
                          v-b-tooltip.hover
                          title="AFE or Cost Center / Well Cancel"
                          >Cancel<i class="ml-2 fa fa-times-circle"></i
                        ></b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <InvoiceTotals
        v-bind:invoice="invoice"
        v-bind:serviceData="serviceData"
        v-bind:currency="currency"
      ></InvoiceTotals>
      <div
        class="mt-4"
        v-if="
          set === 1 &&
            invoice.inv_passed !== 17 &&
            invoice.inv_passed !== 9 &&
            invoice.inv_passed !== 5 &&
            invoice.inv_passed !== 14 &&
            invoice.inv_passed !== 5 &&
            invoice.inv_passed !== 24 &&
            invoice.inv_passed !== 34 &&
            invoice.inv_passed !== 44 &&
            invoice.inv_passed !== 54
        "
      >
        <h5 v-if="invoice.vendor_note">Vendor Comment :</h5>
        <textarea
          v-if="invoice.vendor_note"
          class="form-control mb-3"
          readonly
          >{{ invoice.vendor_note }}</textarea
        >

        <b-modal
          id="add-comments"
          :no-close-on-backdrop="true"
          title="Add Comment"
          size="xl"
          @ok="switchAction()"
        >
          <h5>Please add a comment.</h5>
          <textarea
            name="comments"
            v-model="comments"
            class="w-100 rounded border mt-2"
            rows="3"
          ></textarea>
          <template #modal-footer="{ ok, cancel }">
            <b-button
              size="sm"
              variant="success"
              :disabled="comments.trim().length === 0"
              @click="ok()"
            >
              OK
            </b-button>
            <b-button size="sm" variant="danger" @click="cancel()">
              Cancel
            </b-button>
          </template>
          <i
            class="fa fa-exclamation-triangle"
            v-show="comments.trim().length === 0"
            >Comment is require.</i
          >
        </b-modal>
        <button
          v-if="role != 'Accountant'"
          class="btn btn-danger mr-4 mb-4"
          v-b-modal.add-comments
          @click="submissionAction = 0"
        >
          <i class="fa fa-times-circle"></i> Rejects
        </button>
        <button
          v-else
          class="btn btn-danger mr-4 mb-4"
          v-b-modal.add-comments
          @click="submissionAction = 1"
        >
          <i class="fa fa-times-circle"></i> Reject
        </button>
        <b-dropdown class="mr-4 mb-4" variant="primary" text="Send Back To">
          <b-dropdown-item v-b-modal.add-comments @click="submissionAction = 2"
            >Well Site Supervisor</b-dropdown-item
          >
        </b-dropdown>
        <button
          type="submit"
          class="btn btn-success mb-4 mr-4"
          v-b-modal.add-comments
          @click="submissionAction = 3"
        >
          <i class="fa fa-check"></i> Approve
        </button>
      </div>
      <div v-show="invoiceError" class="d-block">
        <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i
        ><span v-show="invoiceError" class="help"
          >Invoice Error. Please fix invoice or contact admin.</span
        >
      </div>
      <b-modal
        ref="service-tax-modal"
        class="service-tax-modal"
        centered
        size="xl"
        hide-footer
        :title="'Applied Taxes'"
        id="service-tax-modal"
      >
        <table class="tax-table">
          <thead>
            <tr>
              <th class="tax-action-th"></th>
              <th>Tax Name</th>
              <th>Tax Percentage</th>
              <th>Tax Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in selectedServiceTax.applied_taxes">
              <td class="tax-action">
                <input
                  type="checkbox"
                  v-model="val.isApplied"
                  :checked="val.isApplied"
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  :value="val.tax_name"
                  readonly
                  class="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  readonly
                  :value="val.percentage"
                  class="form-control"
                />
              </td>
              <td>
                <input
                  type="text"
                  readonly
                  :value="
                    formatPrice(
                      Number(
                        `${selectedServiceTax.total}`.replaceAll(',', '')
                      ) *
                        (Number(val.percentage) / 100)
                    )
                  "
                  class="form-control"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
      <hr />
      <InvoiceHistory v-bind:history="invoice"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import VueResizable from 'vue-resizable';

import VendorDetails from './../invoice/InvoiceVendorDetails';
import CompanyDetails from './../invoice/InvoiceCompanyDetails';
import InvoiceDetails from './../invoice/InvoiceDetails';
import InvoiceHeader from './../invoice/InvoiceHeader';
import InvoiceAfes from './../invoice/InvoiceAfes';
import InvoiceTotals from './../invoice/InvoiceTotals';
import InvoiceHistory from './../invoice/InvoiceHistory';
import InvoiceStatus from './../../components/InvoiceStatus';
import { RotateSquare2 } from 'vue-loading-spinner';
import MasterInvoice from '../../components/MasterInvoice.vue';

export default {
  name: 'WTSInvoice',
  components: {
    vSelect,
    Datepicker,
    RotateSquare2,
    InvoiceHeader,
    VendorDetails,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
    VueResizable,
    InvoiceStatus,
    'Master-Invoice': MasterInvoice,
  },
  watch: {
    scrollPosition: function(newVal) {
      if (newVal >= 0) {
        this.scrollDown = 'scrollUp';
      }
      if (newVal > 70) {
        this.scrollDown = 'scrollDown';
      }
    },
  },
  data() {
    return {
      width: 200,
      height: 100,
      today: new Date(),
      uom: [
        { value: 'L', label: 'L' },
        { value: 'KG', label: 'KG' },
      ],
      vendorDetails: [],
      serviceNames: [
        { value: 'Drilling', label: 'Drilling', code: '9294' },
        { value: 'Water', label: 'Water', code: '9594' },
      ],
      allafe: [
        { value: '34353', label: '34353' },
        { value: '65434', label: '65434' },
      ],
      service: {
        startdate: '',
        enddate: '',
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      afesTemp: [],
      afenum: '',
      ccone_code: '',
      cctwo_code: '',
      ccthree_code: '',
      percent: 0,
      vwref: [],
      afeData: [],
      gst: 5,
      quickpay: 0,
      services: [],
      netDays: '',
      serviceData: [],
      comments: '',
      invoiceError: false,
      tax_percentage: 0,
      deleteAps: [],
      serviceChainData: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      cc1: [],
      cc2: [],
      cc3: [],
      //highestAfe: [],
      company_name: '',
      invoice: [],
      currency: '',
      tax: '',
      set: 0,
      servicenameSort: '',
      lineAfe: '',
      beforeEditCache: [],
      beforeEditCacheCatch: 0,
      beforeEditCacheIndex: 0,
      beforeEditAfeCache: null,
      beforeEditAfeCacheCatch: 0,
      beforeEditAfeCacheIndex: 0,
      editedAfeLine: null,
      editAfe: 1,
      update: 0,
      serviceid: 0,
      scrollPosition: null,
      scrollDown: 'scrollUp',
      percentage: 0,
      createdServiceLine: 0,
      createAfenum: [],
      createCC1Percentage: 100,
      createCC1Code: [],
      createCC2Code: [],
      createCC3Code: [],
      createAfeData: [],
      cc1CreateData: [],
      cc2CreateData: [],
      cc3CreateData: [],
      createAfe: 0,
      disableEdits: 0,
      afes: [],
      order: '',
      ascDesc: 'asc',
      toggle: false,
      createdUnassignedServiceLine: [],
      createdServiceLineItem: [],
      editAfeChainLine: [],
      loading: false,
      usersName: '',
      role: '',
      projectTypeData: [
        { projecttype: 'Operation Project' },
        { projecttype: 'Afe Project' },
      ],
      projectType: '',
      CC_data: '',
      search: '',
      search_cc: 'costcode1',
      vendorId: 0,
      currentAfeNum: '',
      invoice_vendor_mail: '',
      userData: '',
      oldCcData: '',
      afePercentage: [],
      highlightServices: [],
      dragStartService: {},
      AfeData: [],
      isSortingEnabled: false,
      sortedValue: [],
      submissionAction: 0,
      selectedServiceTax: {},
    };
  },
  props: ['dontRedirect', 'fromexternal'],
  methods: {
    openServiceTax(service) {
      service.total =
        service.quantity * service.pu -
        (service.discount / 100) * service.quantity * service.pu;
      this.selectedServiceTax = service;
      this.$refs['service-tax-modal'].show();
    },
    switchAction() {
      if (this.comments.trim().length == '') {
        return;
      }
      switch (this.submissionAction) {
        case 0:
          return this.submitInvoice(2);
        case 1:
          return this.rejectInvoice(2);
        case 2:
          return this.kickBack(0, 'WSS');
        case 3:
          return this.submitInvoice(1);
      }
    },
    disableSorting() {
      this.isSortingEnabled = false;
      this.sortedValue = [];
      this.serviceData.sort((a, b) => a.vsid - b.vsid);
    },
    sortData(value, order) {
      this.isSortingEnabled = true;
      this.sortedValue = [value, order];
      if (['name', 'type'].includes(value)) {
        this.serviceData.sort((a, b) => {
          return order
            ? a[value] > b[value]
              ? 1
              : -1
            : a[value] < b[value]
            ? 1
            : -1;
        });
      } else if (['pu', 'discount', 'quantity'].includes(value)) {
        this.serviceData.sort((a, b) => {
          const check =
            Number(`${a[value]}`.replaceAll(',', '')) -
            Number(`${b[value]}`.replaceAll(',', ''));
          return order ? -check : check;
        });
      } else if (['discounted_price_per_unit', 'total'].includes(value)) {
        this.serviceData.sort((a, b) => {
          const check =
            Number(
              `${
                value !== 'discounted_price_per_unit'
                  ? a.quantity * a.pu - (a.discount / 100) * a.quantity * a.pu
                  : a.pu * (1 - a.discount / 100)
              }`.replaceAll(',', '')
            ) -
            Number(
              `${
                value !== 'discounted_price_per_unit'
                  ? b.quantity * b.pu - (b.discount / 100) * b.quantity * b.pu
                  : b.pu * (1 - b.discount / 100)
              }`.replaceAll(',', '')
            );
          return order ? -check : check;
        });
      } else if (value === 'uom') {
        this.serviceData.sort((a, b) => {
          return order
            ? a[value][1] > b[value][1]
              ? 1
              : -1
            : a[value][1] < b[value][1]
            ? 1
            : -1;
        });
      } else if (value === 'dates') {
        this.serviceData.sort((a, b) => {
          const check = this.$moment(a.start_date).isSameOrAfter(b.start_date)
            ? 1
            : -1;
          return order ? -check : check;
        });
      } else {
        this.serviceData.sort((a, b) => {
          const check = Number(a.tax) - Number(b.tax);
          return order ? -check : check;
        });
      }
    },
    dragOver(e) {},
    async serviceOnDrop(service, line) {
      if ('afenum' in this.createAfenum && 'afenum' in this.CC_data) {
        this.addAfeUnassignedLine(service);
      } else {
        if (line) {
          this.attachAfes(line);
          this.addAfeUnassignedLine(this.dragStartService);
        } else {
          if (service.afes.length === 0) return;
          this.groupAssigneAfes(this.dragStartService, service.afes);
        }
      }
    },
    groupAssigneAfes(service, line) {
      let send = JSON.stringify({
        serviceid: service.serviceid,
        projectid: this.invoice.projectid,
        afe_data: line,
        companyid: this.invoice.companyid,
        vid: this.invoice.vid,
        vsid: service.vsid,
      });

      this.$http
        .post('/wts/create/costcode_and_assign_service/group_afes/', send)
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.show('Successful Import.', {
              type: 'success',
              duration: '3000',
            });
            this.afes.push(...response.data.addedServices);
          }
        })
        .catch((error) => {
          this.$toasted.show('Error Adding cost Code', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    afeDragStart(line, service) {
      if (line) {
        this.attachAfes(line);
      } else {
        this.createAfenum = {};
        this.CC_data = {};
        this.dragStartService = service;
      }
    },
    attachAfes(line) {
      this.percentage = line.percentage;
      this.createAfenum = {
        afenum: line.afenum,
        items: [{ pwcaid: line.pwcaid, afenum: line.afenum }],
      };
      this.CC_data = {
        afenum: line.afenum,
        companyid: this.companyId,
        costcode1: line.ccone_code,
        costcode2: line.cctwo_code,
        costcode3: line.ccthree_code,
      };
    },

    kickBack(pass, passed_to) {
      var comments =
        this.usersName + ' ($' + this.formatPrice(this.invoice.total) + ')';
      if (this.comments != '') {
        comments += ': ' + this.comments;
      }
      let data = JSON.stringify({
        invnum: this.invoice.invnum,
        inv_pass: pass,
      });
      this.$http
        .post('/wts/edit/invpass_kickback', data)
        .then(async () => {
          this.$toasted.show('Invoice Sent Back Successfully', {
            type: 'success',
            duration: '3000',
          });
          const history_data = this.assignHistoryData(3, passed_to);
          await this.createInvoiceHistory(history_data);
          if (this.role != 'Accountant') {
            this.$router.push('/pm');
          } else {
            this.$router.push('/accountant');
          }
        })
        .catch(() => {});
    },
    removeAll() {
      this.loading = true;
      const data = {
        service_ccid: this.afes.map((val) => val.service_ccid),
        projectid: this.invoice.projectid,
        vid: this.invoice.vid,
      };
      this.$axios
        .post('/wss/delete/service_ap', data)
        .then(() => {
          this.afes = [];
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
          this.disableEdits = 0;
          this.loading = false;
        })
        .catch(() => {});
    },
    editAfeLine(afe, serviceIndex, afeIndex, afes, service) {
      if (
        this.invoice.accountant_paid === 1 ||
        [7, 11, 21, 31, 41, 51].includes(this.invoice.inv_passed)
      ) {
        return;
      }
      if (this.disableEdits == 1) {
        this.$toasted.show('Please save edit first.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      this.editAfeChainLine = {
        afe_num: afe.afenum,
        ccone_code: afe.ccone_code,
        cctwo_code: afe.cctwo_code,
        ccthree_code: afe.ccthree_code,
        percentage: afe.percentage,
        pwcaid: afe.pwcaid,
      };

      this.$axios
        .get(
          '/wss/get/service_chains/' +
            this.invoice.companyid +
            '/' +
            this.invoice.projectid +
            '/' +
            service[0].serviceid +
            '/' +
            1 +
            '/?vid=' +
            this.invoice.vid
        )
        .then((response) => {
          if (response.data.result.length > 0) {
            this.editedAfeLine = afe;
            this.percentage = afe.percentage;
            this.disableEdits = 1;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.serviceChainData = Object.keys(result).map((key) => ({
              afenum: key,
              items: result[key],
            }));

            var currentAfe = this.serviceChainData.findIndex(
              (x) => x.afenum == afe.afenum
            );
            this.merged[serviceIndex][0].afes[
              afeIndex
            ].afenum = this.serviceChainData[currentAfe];
            this.editAfe = 1;
          } else {
            this.editedAfeLine = [];
            this.$toasted.show('No AFE or Cost Center / WellS Found', {
              type: 'error',
              duration: '3000',
            });
            return;
          }
          this.search = '';
          this.cc1Data = response.data.cost_code_chain_data;
          var cc1Index = this.cc1Data.findIndex(
            (x) =>
              x.costcode1 == afe.ccone_code &&
              x.costcode2 == afe.cctwo_code &&
              x.costcode3 == afe.ccthree_code
          );
          this.CC_data = this.cc1Data[cc1Index];
          this.oldCcData = this.cc1Data[cc1Index];
          this.editAfe = 1;
        })
        .catch((err) => {});
    },
    cancelEditAfe(line, service, serviceIndex, afeIndex, afes) {
      const {
        ccthree_code,
        ccone_code,
        afe_num,
        cctwo_code,
        pwcaid,
      } = this.editAfeChainLine;
      service.forEach((val) => {
        let find = this.afes.findIndex(
          (x) =>
            x.vsid == val.vsid &&
            x.ccthree_code == ccthree_code &&
            x.ccone_code == ccone_code &&
            x.cctwo_code == cctwo_code &&
            x.afenum == afe_num
        );
        Vue.set(this.afes, find, {
          afenum: afe_num,
          ccone_code: ccone_code,
          cctwo_code: cctwo_code,
          ccthree_code: ccthree_code,
          percentage: this.percentage,
          service_ccid: this.afes[find].service_ccid,
          apid: this.afes[find].apid,
          vsid: val.vsid,
          pwcaid: pwcaid,
        });
      });
      this.createdUnassignedServiceLine = 0;
      this.disableEdits = 0;
    },
    deleteServiceAfe(line, service) {
      const service_ccid = this.afes
        .filter(
          (val) =>
            val.pwcaid === line.pwcaid &&
            val.vsid === service.vsid &&
            val.ccone_code === line.ccone_code &&
            val.cctwo_code === line.cctwo_code &&
            val.ccthree_code === line.ccthree_code
        )
        .map((val) => val.service_ccid)[0];
      this.$axios
        .delete('/wss/delete/service_ap/service_ccid/' + service_ccid)
        .then(() => {
          this.afes = this.afes.filter(
            (val) => val.service_ccid !== service_ccid
          );
          this.$toasted.show('Coding Removed', {
            type: 'success',
            duration: '3000',
          });
        })
        .catch(() => {
          this.$toasted.show('Error Removing Coding', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    async editAddAfeData(line, service, serviceIndex, afeIndex, afes) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      let cc_num = this.CC_data;
      let isDuplicate = false;
      service.forEach((val) => {
        for (let [i, v] of Object.entries(val.afes)) {
          if (i != afeIndex) {
            if (
              v.afenum == line.afenum.afenum &&
              v.ccone_code == cc_num.costcode1 &&
              v.cctwo_code == cc_num.costcode2 &&
              v.ccthree_code == cc_num.costcode3
            ) {
              isDuplicate = true;
            }
          }
        }
      });
      if (isDuplicate) {
        this.$toasted.show('Duplicates Chain', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      const {
        ccthree_code,
        ccone_code,
        afe_num,
        cctwo_code,
      } = this.editAfeChainLine;
      const vsid_data = service[0].same_coding;
      let ccid_data = this.afes
        .filter(
          (v, i) =>
            v.afenum == afe_num &&
            v.ccone_code == ccone_code &&
            v.cctwo_code == cctwo_code &&
            v.ccthree_code == ccthree_code &&
            vsid_data.includes(v.vsid)
        )
        .map((val) => val.service_ccid);

      let send = JSON.stringify({
        service_ccid: ccid_data,
        projectid: this.invoice.projectid,
        pwcaid: line.afenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.invoice.companyid,
        vid: this.invoice.vid,
        percentage: this.percentage,
      });
      this.$http
        .put('/wts/create/costcode_and_assign_service/update/service_cc', send)
        .then((res) => {
          vsid_data.forEach((vsid) => {
            let find = this.afes.findIndex(
              (x) =>
                x.vsid == vsid &&
                x.ccthree_code == ccthree_code &&
                x.ccone_code == ccone_code &&
                x.cctwo_code == cctwo_code &&
                x.afenum == afe_num
            );
            Vue.set(this.afes, find, {
              afenum: line.afenum.afenum,
              ccone_code: cc_num.costcode1,
              cctwo_code: cc_num.costcode2,
              ccthree_code: cc_num.costcode3,
              percentage: this.percentage,
              service_ccid: this.afes[find].service_ccid,
              apid: this.afes[find].apid,
              vsid: vsid,
              pwcaid: line.afenum.items[0].pwcaid,
            });
          });
          this.createdUnassignedServiceLine = 0;
          this.disableEdits = 0;
        })
        .catch((err) => {});
    },
    addNewUnassignedAfeLine(afe) {
      this.$http
        .get(
          '/wss/get/service_chains/' +
            this.invoice.companyid +
            '/' +
            this.invoice.projectid +
            '/' +
            afe.id +
            '/' +
            1 +
            '/?vid=' +
            this.invoice.vid
        )
        .then((response) => {
          if (response.data.result.length > 0) {
            this.createdUnassignedServiceLine = afe;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.createAfeData = Object.keys(result).map((key) => ({
              afenum: key,
              items: result[key],
            }));
            this.AfeData = this.createAfeData;
            this.createAfenum = this.createAfeData[0];
            this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
          } else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFE or Cost Center / WellS Found', {
              type: 'error',
              duration: '3000',
            });
            return;
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data = this.cc1CreateData[0];
          this.oldCcData = this.cc1CreateData[0];
          this.createAfe = 1;
        })
        .catch((err) => {});
    },
    removeHighlightService() {
      this.highlightServices = [];
    },
    highlightService(service) {
      this.highlightServices = [];
      this.highlightServices = service.map((val) => val.vsid);
    },
    addNewAfeLine(afe, service) {
      this.$axios
        .get(
          '/wss/get/service_chains/' +
            this.invoice.companyid +
            '/' +
            this.invoice.projectid +
            '/' +
            service.serviceid +
            '/' +
            1 +
            '/?vid=' +
            this.invoice.vid
        )
        .then((response) => {
          if (response.data.result.length > 0) {
            this.createdServiceLine = afe;
            this.createdServiceLineItem = service;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.createAfeData = Object.keys(result).map((key) => ({
              afenum: key,
              items: result[key],
            }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
          } else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFE or Cost Center / WellS Found', {
              type: 'error',
              duration: '3000',
            });
            return;
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data = this.cc1CreateData[0];
          this.oldCcData = this.cc1CreateData[0];
          this.createAfe = 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getcc1(serviceIndex, afeIndex) {
      if (
        this.editAfe == 0 &&
        this.merged[serviceIndex][0].afes[afeIndex].afenum.items
      ) {
        var cc1Temp = this.lodash.groupBy(
          this.merged[serviceIndex][0].afes[afeIndex].afenum.items,
          'ccone_code'
        );
        this.cc1Data = Object.keys(cc1Temp).map((key) => ({
          ccone_code: key,
          items: cc1Temp[key],
        }));
        this.merged[serviceIndex][0].afes[
          afeIndex
        ].ccone_code = this.cc1Data[0];
      }
    },
    getcc2(serviceIndex, afeIndex) {
      if (
        this.editAfe == 0 &&
        this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items
      ) {
        var cc2Temp = this.lodash.groupBy(
          this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items,
          'cctwo_code'
        );
        this.cc2Data = Object.keys(cc2Temp).map((key) => ({
          cctwo_code: key,
          items: cc2Temp[key],
        }));
        this.merged[serviceIndex][0].afes[
          afeIndex
        ].cctwo_code = this.cc2Data[0];
      }
    },
    getcc3(serviceIndex, afeIndex) {
      if (
        this.editAfe == 0 &&
        this.merged[serviceIndex][0].afes[afeIndex].cctwo_code.items
      ) {
        var cc3Data = this.merged[serviceIndex][0].afes[
          afeIndex
        ].cctwo_code.items.filter((o) => !o.ccthree_code.includes('---'));
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3Data = Object.keys(cc3Temp).map((key) => ({
          ccthree_code: key,
          items: cc3Temp[key],
        }));
        this.merged[serviceIndex][0].afes[
          afeIndex
        ].ccthree_code = this.cc3Data[0];
      }
    },
    getcc1Create(serviceIndex) {
      if (this.createAfe == 0 && this.createAfenum.items) {
        var cc1Temp = this.lodash.groupBy(
          this.createAfenum.items,
          'ccone_code'
        );
        this.cc1CreateData = Object.keys(cc1Temp).map((key) => ({
          ccone_code: key,
          items: cc1Temp[key],
        }));
        this.createCC1Code = this.cc1CreateData[0];
      }
    },
    getcc2Create(serviceIndex) {
      if (this.createAfe == 0 && this.createCC1Code.items) {
        var cc2Temp = this.lodash.groupBy(
          this.createCC1Code.items,
          'cctwo_code'
        );
        this.cc2CreateData = Object.keys(cc2Temp).map((key) => ({
          cctwo_code: key,
          items: cc2Temp[key],
        }));
        this.createCC2Code = this.cc2CreateData[0];
      }
    },
    getcc3Create(serviceIndex) {
      if (this.createAfe == 0 && this.createCC2Code.items) {
        var cc3Data = this.createCC2Code.items.filter(
          (o) => !o.ccthree_code.includes('---')
        );
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3CreateData = Object.keys(cc3Temp).map((key) => ({
          ccthree_code: key,
          items: cc3Temp[key],
        }));
        this.createCC3Code = this.cc3CreateData[0];
      }
    },
    addAfeUnassignedLine(service) {
      var cc_num = [];
      cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;

      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Center / Well Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      let isDuplicate = false;
      if (service.afes.length > 0) {
        service.afes.forEach((v) => {
          if (
            v.afenum == this.createAfenum.afenum &&
            v.ccone_code == cc_num.costcode1 &&
            v.cctwo_code == cc_num.costcode2 &&
            v.ccthree_code == cc_num.costcode3
          ) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate) {
        this.$toasted.show('Duplicates Chain', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let service_data = this.serviceData.filter(
        (x, i) => this.serviceData[i].vsid == service.vsid
      );
      let send = JSON.stringify({
        serviceids: service_data,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        projectType: 1,
        companyid: this.invoice.companyid,
        projectid: this.invoice.projectid,
        vid: this.invoice.vid,
        afenum: this.createAfenum.afenum,
        percentage: this.percentage,
      });

      this.AddServiceToAFe(send);
    },
    AddServiceToAFe(send) {
      this.$http
        .post('/wts/create/costcode_and_assign_service', send)
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.show('Successful Import.', {
              type: 'success',
              duration: '3000',
            });
            this.afes.push(...response.data.addedServices);
            this.createdUnassignedServiceLine = 0;
            this.disableEdits = 0;
          }
        })
        .catch((error) => {
          this.$toasted.show('Error Adding cost Code', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    addAfeLine(afes, line) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Center / Well Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      this.addAfeUnassignedLine(afes[0]);
    },
    async addAfeToAllInvoiceServices(isGroup, groupService) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Center / Well Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;
      if (this.afes.filter((e) => e.afenum === cc_num.afenum).length > 0) {
        this.$toasted.show(
          'AFE or Cost Center / Well Duplicate For a line item',
          { type: 'error', duration: '3000' }
        );
        return;
      }

      let send = JSON.stringify({
        serviceids: isGroup
          ? this.serviceData.filter((val) =>
              groupService.same_coding.includes(val.vsid)
            )
          : this.serviceData,
        projectid: this.invoice.projectid,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.invoice.companyid,
        projectType: 1,
        vid: this.invoice.vid,
        afenum: this.createAfenum.afenum,
        percentage: this.percentage,
      });

      await this.AddServiceToAFe(send);

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = false;
    },
    removeAllAfe: function(service) {
      const data = {
        service_ccid: this.afes
          .filter((val) => val.vsid === service.vsid)
          .map((val) => val.service_ccid),
        projectid: this.invoice.projectid,
        vid: this.invoice.vid,
      };
      this.$axios
        .post('/wss/delete/service_ap', data)
        .then(() => {
          this.afes = this.afes.filter((x) => x.vsid !== service.vsid);
          this.editAfe = 0;
          this.createAfe = 0;
          this.disableEdits = 0;
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
        })
        .catch(() => {});
    },
    cancelAfe: function() {
      this.editAfe = 0;
      this.createAfe = 0;
      this.disableEdits = 0;
      this.createdServiceLine = [];
      this.createdUnassignedServiceLine = [];
    },
    rejectInvoice(status) {
      var comments =
        this.usersName + ' ($' + this.formatPrice(this.invoice.total) + ')';
      if (this.comments != '') {
        comments += ': ' + this.comments;
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        ac_note: comments,
        wts_stat: status,
        email: this.invoice_vendor_mail,
        comments: comments,
        usersName: this.usersName,
        invnum: this.invoice.invnum,
        fname: this.invoice.vfname,
        lname: this.invoice.vlname,
      });

      this.$http
        .get('/wts/remove/qbyte_invoice/' + this.invoice.invoiceid)
        .then((res) => {
          if (res.data.status != null) {
            this.$http
              .post('/accountant/edit/invoice', data)
              .then(async (response) => {
                this.$toasted.show('Invoice Updated Successfully', {
                  type: 'success',
                  duration: '3000',
                });
                const history_data = this.assignHistoryData(status);
                if (
                  'errorMsg' in response.data &&
                  response.data.errorMsg != null
                )
                  history_data['invoiceData']['rejectionError'] =
                    response.data.errorMsg;
                await this.createInvoiceHistory(history_data);
                this.$router.push('/accountant');
              })
              .catch((error) => {
                this.invoiceError = true;
              });
          } else {
            this.$toasted.show('Invoice Is Already Processed in qbyte', {
              type: 'error',
              duration: '5000',
            });
          }
        })
        .catch(() => {
          this.$toasted.show('Error', { type: 'error', duration: '5000' });
        });
    },
    assignHistoryData(status, passed_to) {
      return {
        role: `${
          this.role !== 'Accountant' ? 'Project Manager' : 'Accountant'
        }`,
        role_id: this.userData[0].wtsid,
        userName: this.usersName,
        comments: this.comments,
        action_desc:
          status === 2
            ? 'Rejected'
            : status === 3
            ? `Send Back To ${passed_to}`
            : 'Approved',
        action_status: status === 2 ? -1 : status === 3 ? 4 : 5, // -1 for rejected, 4 for send back to, 5 for approval
        invoiceData: {
          invoiceid: this.invoice.invoiceid,
          companyid: this.invoice.companyid,
          projectid: this.invoice.projectid,
          vid: this.invoice.vid,
          previousInvoiceStatus: this.invoice.inv_passed,
          projectname: this.vwref.projectname,
          msg: `Edited By ${
            this.role !== 'Accountant' ? 'Project Manager' : 'Accountant'
          }`,
        },
      };
    },
    submitInvoice(status) {
      if (status === 1 && this.afes.length === 0) {
        this.$toasted.show('AFE or Cost Center / Well Table Not Complete.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      if (status === 1) {
        for (var i = 0; i < this.serviceData.length; i++) {
          var temp = this.afes.filter(
            (x) => x.vsid == this.serviceData[i].vsid
          );
          if (temp.length == 0) {
            this.$toasted.show(
              'AFE or Cost Center / Well Table Not Complete. ' +
                this.serviceData[i].name +
                ' does not have AFE or Cost Center / Well assigned.',
              { type: 'error', duration: '3000' }
            );
            return;
          }
        }
      }

      this.afePercentage = [];
      if (this.merged.length > 0) {
        this.merged.forEach((val, i) => {
          let sum = 0;
          val[0].afes.forEach((data, j) => {
            sum = sum + Number(data.percentage);
            if (j === val[0].afes.length - 1 && sum !== 100) {
              this.afePercentage.push(val[0].vsid);
            }
          });
        });
        if (this.afePercentage.length > 0) {
          this.$toasted.show(
            'Coding percentage is not 100%, please review coding percentage.',
            { type: 'error', duration: '3000' }
          );
          return;
        }
      }

      if (this.beforeEditAfeCacheCatch == 1) {
        this.$toasted.show('Please save service', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      var comments =
        this.usersName + ' ($' + this.formatPrice(this.invoice.total) + ')';
      if (this.comments != '') {
        comments += ': ' + this.comments;
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.serviceData[0].projectid,
        wts_stat: status,
        wts_note: comments,
        role: this.role,
        email: this.invoice_vendor_mail,
        comments: comments,
        usersName: this.usersName,
        invnum: this.invoice.invnum,
        fname: this.invoice.vfname,
        lname: this.invoice.vlname,
      });

      this.$http
        .post('/wts/edit/invoice', data)
        .then(async (response) => {
          if (response.status != 200) {
            this.$toasted.show('Error.', { type: 'error', duration: '3000' });
            this.error = true;
          }
          this.$toasted.show('Invoice Submitted Successfully', {
            type: 'success',
            duration: '3000',
          });
          const history_data = this.assignHistoryData(status);
          if ('errorMsg' in response.data && response.data.errorMsg != null)
            history_data['invoiceData']['rejectionError'] =
              response.data.errorMsg;
          await this.createInvoiceHistory(history_data);
          if (this.dontRedirect) {
            this.$emit('onStatusUpdate', this.invoice.invoiceid);
          } else {
            if (this.role != 'Accountant') {
              this.$router.push('/pm');
            } else {
              this.$router.push('/accountant');
            }
          }
          this.set = this.$session.set('set', 0);
        })
        .catch((error) => {
          this.invoiceError = true;
        });
    },
    loadUwis() {
      this.$axios
        .get(
          '/wss/get/service_chains/' +
            this.invoice.companyid +
            '/' +
            this.invoice.vid
        )
        .then((response) => {
          this.uwiData = response.data.result;
          this.uwiData.forEach(
            (val) =>
              (val.uwi_4 = `${val.uwi !== null ? val.uwi : ''}-${
                val.uwi_2 !== null ? val.uwi_2 : ''
              }-${val.uwi_3 !== null ? val.uwi_3 : ''}-${val.pwid}`)
          );
          this.vwref = this.uwiData.filter(
            (x) =>
              x.uwi === this.invoice.vwref &&
              x.projectid === this.invoice.projectid
          )[0];
        })
        .catch(() => {});
    },
    loadData() {
      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;

      this.$http
        .get('/wss/get/invoice_services_chain/' + pid + '/' + invid)
        .then((response) => {
          if (response.data.result.length > 0) {
            var temp = response.data.result;
            for (var i = 0; i < temp.length; i++) {
              var index = this.serviceData.findIndex(
                (x) => x.vsid === temp[i].vsid
              );
              this.afes.push({
                name: temp[i].name,
                start_date: temp[i].start_date,
                serviceid: temp[i].serviceid,
                end_date: temp[i].end_date,
                vsid: temp[i].vsid,
                afenum: temp[i].afenum,
                ccone_code: temp[i].ccone_code,
                cctwo_code: temp[i].cctwo_code,
                ccthree_code: temp[i].ccthree_code,
                percentage: temp[i].percentage,
                service_ccid: temp[i].service_ccid,
                apid: temp[i].apid,
                uwi: temp[i].uwi,
                uwi_2: temp[i].uwi_2,
                uwi_3: temp[i].uwi_3,
                lsdsurface: temp[i].lsdsurface,
                lsdbottom: temp[i].lsdbottom,
                ln: temp[i].ln,
                pwcaid: temp[i].pwcaid,
              });
            }
            this.afes = this.lodash.uniqBy(this.afes, 'apid');
          }
        })
        .catch((error) => {
          this.$toasted.show('Error.', { type: 'error', duration: '3000' });
        });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  computed: {
    filtered() {
      if (this.oldCcData.costcodechainid !== this.CC_data.costcodechainid) {
        this.search = '';
        this.oldCcData = this.CC_data;
      }
      if (this.search) {
        this.search = this.search.toUpperCase();
        const query = new RegExp(`^${this.search}`);
        const combined_data = this.cc1CreateData.filter((x) =>
          query.test(
            `${x.costcode1}-${x.costcode2}-${x.costcode3}`.toUpperCase()
          )
        );
        const cc1 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode1}`.toUpperCase())
        );
        const cc2 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode2}`.toUpperCase())
        );
        const cc3 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode3}`.toUpperCase())
        );
        if (combined_data.length > 0) return combined_data;
        if (cc1.length > 0) return cc1;
        if (cc2.length > 0) return cc2;
        if (cc3.length > 0) return cc3;
      } else {
        return this.cc1CreateData;
      }
    },
    paginated() {
      return this.filtered;
    },
    filterAfeAttachedCostCodes() {
      if (this.oldCcData.costcodechainid !== this.CC_data.costcodechainid) {
        this.search = '';
        this.oldCcData = this.CC_data;
      }
      if (this.search) {
        this.search = this.search.toUpperCase();
        const query = new RegExp(`^${this.search}`);
        const combined_data = this.cc1Data.filter((x) =>
          query.test(
            `${x.costcode1}-${x.costcode2}-${x.costcode3}`.toUpperCase()
          )
        );
        const cc1 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode1}`.toUpperCase())
        );
        const cc2 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode2}`.toUpperCase())
        );
        const cc3 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode3}`.toUpperCase())
        );
        if (combined_data.length > 0) return combined_data;
        if (cc1.length > 0) return cc1;
        if (cc2.length > 0) return cc2;
        if (cc3.length > 0) return cc3;
      } else {
        return this.cc1Data;
      }
    },
    afeAttachedCostCodes() {
      return this.filterAfeAttachedCostCodes;
    },
    highestAfe: function() {
      if (this.afes.length > 0) {
        var res = Math.max.apply(
          Math,
          this.afes.map(function(o) {
            return o.percentage;
          })
        );
        return this.afes.find(function(o) {
          return o.percentage == res;
        });
      }
    },
    subtotal: function() {
      return this.serviceData.reduce(function(total, item) {
        return (
          total +
          item.quantity * item.pu -
          (item.discount / 100) * item.quantity * item.pu
        );
      }, 0);
    },
    unassigned: function() {
      var unassigned = [];
      for (var i = 0; i < this.serviceData.length; i++) {
        var filtered = this.afes.filter(
          (x) => x.vsid == this.serviceData[i].vsid
        );
        if (filtered.length == 0) {
          unassigned.push(this.serviceData[i]);
        }
      }
      return unassigned;
    },
    merged: function() {
      this.highlightServices = [];
      var dict = {};
      this.serviceData.forEach((service) => {
        var filtered = this.afes.filter((x) => x.vsid == service.vsid);
        service.afes = [];
        filtered.forEach((afe) => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
            pwcaid: afe.pwcaid,
          });
        });
      });

      this.serviceData.forEach((service) => {
        if (service.afes.length > 0) {
          dict[JSON.stringify(service)] = [];
        }
      });

      this.serviceData.forEach((service) => {
        service.afes.forEach((afe) => {
          dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(
            afe
          );
        });
      });

      var dictAfe = {};

      for (var service in dict) {
        let key = JSON.stringify(dict[service]);
        if (!(key in dictAfe)) {
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }
      var temp = [];
      for (var service in dictAfe) {
        dictAfe[service].forEach((val, i) => {
          val['show_afe'] = false;
          if (i === 0) val['isTop'] = true;
          val['same_coding'] = dictAfe[service].map((val) => val.vsid);
          if (i === dictAfe[service].length - 1) {
            const combined_total = dictAfe[service].reduce((acc, val) => {
              const service_total =
                val.quantity * val.pu -
                (val.discount / 100) * val.quantity * val.pu;
              return acc + Number(service_total);
            }, 0);
            val['combined_total'] = combined_total;
            val['show_afe'] = true;
            val['isTop'] = i === 0 ? true : false;
          }
        });

        temp.push(dictAfe[service]);
      }
      return temp.flat(Infinity).map((val) => [val]);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);

    if (!this.$route.params.invoice) {
      this.invoice = this.$session.get('invoice');
      this.set = this.$session.get('set');
      this.role = this.$session.get('role');
    } else {
      this.invoice = this.$route.params.invoice;
      this.set = this.$route.params.set;
      this.role = this.$route.params.role;
    }
    this.invoiceID = this.invoice.invoiceid;
    this.quickpay = this.invoice.discount;
    this.gst = this.invoice.tax_percentage;
    var a = this.$moment(this.invoice.endtday);
    var b = this.$moment(this.invoice.creation_date);
    this.netDays = a.diff(b, 'days');

    var pid = this.invoice.projectid;
    var invid = this.invoice.invoiceid;

    this.$http
      .get('/wts/get/unassigned_services/' + pid + '/' + invid)
      .then((response) => {
        this.serviceData = response.data.result;
        this.disableSorting();
        var data = this.serviceData;
        this.invoice_vendor_mail = this.serviceData[0].email;
        this.loadUwis(data);
      })
      .catch((error) => {
        this.$toasted.show('Error.', { type: 'error', duration: '3000' });
      });

    this.$http
      .get('/wts/get/profile')
      .then((response) => {
        this.company_name = response.data.company;
        this.usersName = response.data.fname + ' ' + response.data.lname;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        this.userData = [
          {
            cn: this.company_name,
            un: this.usersName,
            email: response.data.email,
            role: this.role,
            date: today,
            invoice: this.invoice,
            wtsid: response.data.wtsid,
          },
        ];
      })
      .catch((error) => {
        this.$toasted.show('Error.', { type: 'error', duration: '3000' });
      });

    this.loadData();
  },
};
</script>
<style>
.highlightService {
  background-color: #1e7e34 !important;
  opacity: 0.6;
  color: white !important;
}

.afeBorder {
  border-bottom: 2px solid red;
}

.isTop {
  border-top: 1.02px solid red;
}

#v-select1 .vs__actions {
  align-items: center;
}
.add-coding {
  border-radius: 4px;
  color: white;
  border-color: transparent;
}

.add-coding:hover {
  border-radius: 4px;
  color: #1e7e34;
  background-color: white;
  border-color: #1e7e34;
}

.container {
  cursor: grab;
  overflow: auto;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

#editAfe {
  cursor: pointer;
}
#afe-filter {
  display: flex;
  justify-content: end;
  padding: 10px;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment:hover {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: #1e7e34;
  font-size: small;
  background-color: white;
  font-weight: bold;
  border-color: #1e7e34;
}

#total-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#total-table td {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
  font-size: medium;
  font-family: 'proxima-nova';
  font-weight: 700;
}

#total-table th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  background-color: #4b9955;
  color: white;
}

@media only screen and (max-width: 980px) {
  #total-row {
    display: block;
  }
}

#Service-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#Service-table #Service-table-tr td,
#Service-table #Service-table-tr th {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
}

#Service-table #Service-table-tr th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: dimgrey;
  color: white;
}

#Service-table-child {
  font-size: inherit;
  background-color: white;
  /* padding: 10px; */
}

#Service-table-child th {
  font-size: smaller;
}

@media only screen and (max-width: 1200px) {
  #Service-table table,
  #Service-table thead,
  #Service-table tbody,
  #Service-table th,
  #Service-table td,
  #Service-table tr {
    display: block;
  }
  #Service-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #Service-table tr {
    border: 1px solid #ccc;
  }

  #Service-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  #Service-table td:before {
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bolder;
  }

  #Service-table-child td:nth-of-type(1):before {
    content: 'AFE or Cost Center / Well: ';
  }
  #Service-table-child td:nth-of-type(2):before {
    content: 'Cost Code Chain: ';
  }
  #Service-table-child td:nth-of-type(3):before {
    content: 'Coding Percentage %: ';
  }
  #Service-table-child td:nth-of-type(4):before {
    content: 'Action';
  }

  #Service-table-tr td:nth-of-type(1):before {
    content: 'Service Name: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(2):before {
    content: 'Service Code: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(3):before {
    content: 'Start- End date: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(4):before {
    content: 'UOM: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(5):before {
    content: 'Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(6):before {
    content: 'Discounted Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(7):before {
    content: 'Quantity: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(8):before {
    content: 'Discount %: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(9):before {
    content: 'Total: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(10):before {
    content: 'Tax: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(11):before {
    content: 'Action: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
}
.tax-table {
  width: 100%;
  border-spacing: 7px;
  border-collapse: separate;
}
.tax-action-th {
  width: 30px;
}
</style>
